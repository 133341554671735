export const languageTranslations = {
  selectLanguage: {
    ru: "Выберите язык",
    ky: "Тилди тандаңыз",
    tg: "Забонро интихоб кунед",
    uz: "Tilni tanlang",
    zh: "选择语言",
    en: "Select language",
    es: "Seleccionar idioma",
    fr: "Sélectionner la langue",
    ka: "აირჩიეთ ენა",
    vi: "Chọn ngôn ngữ",
    kk: "Тілді таңдаңыз",
    hi: "भाषा चुनें",
    hy: "Ընտրեք լեզուն",
    ar: "اختر اللغة",
    tr: "Dil seçin",
    mn: "Хэл сонгох",
  },
  language: {
    ru: "Язык",
    ky: "Тил",
    tg: "Забон",
    uz: "Til",
    zh: "语言",
    en: "Language",
    es: "Idioma",
    fr: "Langue",
    ka: "ენა",
    vi: "Ngôn ngữ",
    kk: "Тіл",
    hi: "भाषा",
    hy: "Լեզու",
    ar: "اللغة",
    tr: "Dil",
    mn: "Хэл",
  },
};

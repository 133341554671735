<!-- eslint-disable no-irregular-whitespace -->
<template>
  <div class="foreigner">
    <div class="foreigner-head">
      <Card v-for="(item, index) in items" :key="index" :color="item.color">
        <button @click="scroll(`#foreigner_${index}`)">
          <span>
            <img :src="item.icon" />
          </span>
          <h5>{{ item.name }}</h5>
        </button>
      </Card>
    </div>
    <div class="foreigner-body">
      <Card
        v-for="(item, index) in items"
        :key="index"
        :id="`foreigner_${index}`"
        :color="item.color"
      >
        <div class="foreigner-content">
          <h4>{{ item.name }}</h4>
          <div v-if="index === 0" class="foreigner-grid">
            <ContentItem color="grey">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">1</div>
                  <p>
                    Переведите ваш паспорт в бюро переводов и заверьте
                    у нотариуса.
                  </p>
                </div>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">2</div>
                  <p>
                    Оформите СНИЛС в Социальном фонде России, МФЦ или у вашего
                    работодателя.
                  </p>
                </div>
              </template>
            </ContentItem>
          </div>
          <div v-else-if="index === 1" class="foreigner-grid">
            <ContentItem color="white">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">1</div>
                  <p>
                    Возьмите паспорт, оригинал нотариально заверенного перевода
                    и СНИЛС. Также вам потребуется электронная почта или номер
                    телефона российского оператора.
                  </p>
                </div>
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">2</div>
                  <p>Обратитесь в банк или МФЦ.</p>
                </div>
              </template>
            </ContentItem>
          </div>
          <div v-else-if="index === 2" class="foreigner-grid">
            <ContentItem color="white">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">1</div>
                  <p>
                    Возьмите паспорт, оригинал нотариально заверенного перевода
                    и СНИЛС. Также вам потребуется электронная почта.
                  </p>
                </div>
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">2</div>
                  <p>
                    Обратитесь в банк и попросите снять ваши биометрические
                    данные и внести их в Единую биометрическую систему.
                  </p>
                </div>
              </template>
            </ContentItem>
          </div>
          <div v-else-if="index === 3" class="foreigner-grid">
            <ContentItem color="white">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">1</div>
                  <p>
                    С паспортом и оригиналом его нотариально заверенного
                    перевода обратитесь в точку продаж t2 с устройством, в
                    котором планируете использовать SIM. Для заключения договора
                    потребуется указать его IMEI (серийный номер устройства)
                  </p>
                </div>
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">2</div>
                  <p>
                    Сотрудник подготовит договор и сделает проверку сведений
                    о вас по биометрии.
                  </p>
                </div>
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #body>
                <div class="foreigner-body">
                  <div class="foreigner-number">3</div>
                  <p>
                    Для активации SIM через Госуслуги авторизуйтесь под своей
                    учетной записью на устройстве с наличием камеры. Также вы
                    всегда можете обратиться за помощью в точку продаж t2.
                  </p>
                </div>
              </template>
            </ContentItem>
          </div>
          <p v-if="item.caption">{{ item.caption }}</p>
          <ul v-if="item.links" class="foreigner-list">
            <li v-for="link in item.links" :key="link.name">
              <a
                :class="[...link.icons]"
                :href="link.url"
                target="_blank"
                rel="noopener noreferrer"
                ><span>{{ link.name }}</span></a
              >
            </li>
          </ul>
        </div>
      </Card>
    </div>
  </div>
</template>

<script setup>
import Card from "./Card.vue";
import ContentItem from "./ContentItem.vue";

const items = [
  {
    color: "white",
    name: "1. Получите СНИЛС",
    icon: require(`@/assets/foreigner/1.svg`),
    caption: "Номер СНИЛС выпустят при вас",
    links: [
      {
        name: "Список нотариусов",
        icons: ["list"],
        url: "https://data.notariat.ru/directory/notary/",
      },
      {
        name: "Карта социального фонда",
        icons: ["marker"],
        url: "https://sfr.gov.ru/",
      },
      {
        name: "Карта отделов МФЦ",
        icons: ["marker"],
        url: "https://моидокументы.рф/search/mfc",
      },
    ],
  },
  {
    color: "light-blue",
    name: "2. Зарегистрируйтесь на Госуслугах",
    icon: require(`@/assets/foreigner/2.svg`),
    caption: "Учетная запись будет подтверждена в день обращения",
    links: [
      {
        name: "Карта банков и МФЦ",
        icons: ["marker", "white"],
        url: "https://map.gosuslugi.ru/",
      },
    ],
  },
  {
    color: "green-dark",
    name: "3. Сдайте биометрию",
    icon: require(`@/assets/foreigner/3.svg`),
    caption: "Биометрия будет подтверждена и зарегистрирована в день обращения",
    links: [
      {
        name: "Карта банков с биометрией",
        icons: ["marker"],
        url: "https://map.gosuslugi.ru/",
      },
    ],
  },
  {
    color: "pink",
    name: "4. Оформите SIM-карту с выгодным тарифом",
    icon: require(`@/assets/foreigner/4.svg`),
    caption:
      "После подтверждения личности по биометрии для активации SIM-карты вставьте её в устройство, IMEI которого указан в договоре",
    links: [
      {
        name: "Адреса салонов t2",
        icons: ["marker", "white"],
        url: "https://t2.ru/offices",
      },
      {
        name: "Подтверждение на Госуслугах",
        icons: ["check"],
        url: "https://www.gosuslugi.ru/655871/1/form",
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.foreigner {
  display: grid;
  gap: var(--widthGapH);
  &-head {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &:deep(.card) {
      padding: 0;
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        button {
          span:nth-child(1) {
            background-color: #01010133;
          }
        }
      }
    }

    button {
      @include reset-button;
      width: 100%;
      height: 100%;
      display: grid;
      text-align: left;
      padding: var(--widthPadding);
      gap: var(--widthGapV);
      & span:nth-child(1) {
        width: 48px;
        height: 48px;
        display: grid;
        place-content: center;
        border-radius: 16px;
        background-color: var(--colorGrey);
      }
      img {
        width: auto;
        height: auto;
      }
    }
  }
  &-body {
    display: grid;
    gap: var(--widthGapV);
  }
  &-content {
    display: grid;
    gap: var(--widthGapV);
  }
  &-grid {
    display: grid;
    gap: var(--widthGapV);
    color: var(--colorBlack);
    @include md {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &-body {
    display: grid;
    gap: var(--widthGapV);
  }
  &-number {
    background-color: var(--colorBlack);
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: grid;
    color: var(--colorWhite);
    place-items: center;
    font-weight: 800;
    font-size: 12px;
    line-height: 1;
    font-family: var(--fontSecondary);
  }
  &-list {
    @include reset-list;
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-top: 8px;
    @include md {
      flex-direction: row;
      gap: 24px;
    }
    li {
      a {
        text-decoration: none;
        padding-left: 28px;
        position: relative;
        display: inline-grid;
        span {
          text-decoration: underline;
        }
        &:hover {
          span {
            text-decoration: none;
          }
        }
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          width: 24px;
          height: 24px;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
        &.list {
          &::before {
            content: "";
            background-image: url(~@/assets/foreigner/5.svg);
          }
        }
        &.marker {
          &::before {
            content: "";
            background-image: url(~@/assets/foreigner/6.svg);
          }
          &.white {
            &::before {
              background-image: url(~@/assets/foreigner/7.svg);
            }
          }
        }
        &.check {
          &::before {
            content: "";
            background-image: url(~@/assets/foreigner/8.svg);
          }
        }
      }
    }
  }
}
</style>

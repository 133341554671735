export const tariffs = {
  "my-conversation": {
    image: "tariff-card-1.png",
    title: "Мой разговор",
    rubs: 2,
    gb: 1,
    min: 2,
    sms: 0,
    list: [
      "Пользуется услугами связи <strong>только в своём регионе</strong>",
      "Совершает <strong>небольшое количество звонков</strong> на разные номера",
      "<strong>Не пользуется</strong> SMS",
      "<strong>Не слишком активно</strong> пользуется интернетом",
    ],
  },
  "my-online": {
    image: "tariff-card-2.png",
    title: "Мой онлайн",
    rubs: 3,
    gb: 2,
    min: 3,
    sms: 0,
    social_media: [
      "vk",
      "ok",
      "whatsapp",
      "viber",
      "tamtam",
      "telegram",
      "discord",
    ],
    wink: "Подписка на <strong>Wink Basic</strong>",
    list: [
      "Использует <strong>интернет в поездках по России,</strong><br>как в домашнем регионе",
      "Совершает <strong>большое количество звонков</strong> на разные номера",
      "Использует для общения <strong>соцсети и мессенджеры</strong>",
    ],
  },
  "my-online-plus": {
    image: "tariff-card-3.png",
    title: "Мой онлайн+",
    rubs: 4,
    gb: 4,
    min: 3,
    sms: 0,
    social_media: [
      "vk",
      "ok",
      "whatsapp",
      "viber",
      "tamtam",
      "telegram",
      "discord",
      "tik-tok",
    ],
    wink: "Подписка на <strong>Wink Optimum+</strong>",
    list: [
      "Пользуется услугами связи <strong>в разных регионах</strong> России",
      "Совершает <strong>большое количество звонков</strong> на разные номера, в том числе по России",
      "Использует для общения <strong>соцсети и мессенджеры</strong>",
      "<strong>Очень активно пользуется интернетом</strong> для разных задач на смартфоне или планшете",
    ],
  },
  gaming: {
    image: "tariff-card-4.png",
    title: "ИГРОВОЙ",
    rubs: 4,
    gb: 4,
    min: 4,
    sms: 4,
    social_media: ["youtube", "twitch", "discord"],
    game_option: "Игровая опция",
    list: [
      "Пользуется услугами связи <strong>в разных регионах</strong> России",
      "Совершает <strong>большое количество звонков</strong> на разные номера, в том числе по России",
      "Активно играет в онлайн-игры <strong>World of Tanks, World of Warplanes, World of Warships</strong>",
      "Использует для общения <strong>соцсети и мессенджеры</strong>",
    ],
  },
  black: {
    image: "tariff-card-5.png",
    black: true,
    title: "BLACK",
    rubs: 4,
    gb: 4,
    min: 4,
    sms: 4,
    social_media: [
      "vk",
      "ok",
      "whatsapp",
      "viber",
      "tamtam",
      "telegram",
      "tik-tok",
      "youtube",
    ],
    wink: "Подписка на <strong>Wink Optimum+</strong>",
    list: [
      "Пользуется услугами связи <strong>в разных регионах</strong> России",
      "Совершает <strong>очень много звонков</strong> на разные номера, в том числе по России",
      "<strong>Очень активно пользуется интернетом</strong> для разных задач",
    ],
  },
  premium: {
    image: "tariff-card-6.png",
    title: "PREMIUM",
    rubs: 4,
    gb: 4,
    min: 4,
    sms: 4,
    list: [
      "Совершает <strong>очень много звонков</strong> на разные номера, в том числе по России",
      "<strong>Очень активно</strong> пользуется <strong>интернетом</strong> для разных задач",
      "Пользуется услугами связи в разных регионах России",
      "Имеет <strong>повышенные требования к качеству обслуживанию</strong>",
    ],
  },
  classic: {
    image: "tariff-card-7.png",
    title: "Классический",
    rubs: 1,
    gb: 0,
    min: 1,
    sms: 1,
    list: [
      "<strong>Нуждается в тарифе без абонентской платы</strong>",
      "Совершает <strong>мало звонков</strong> и практически не пользуется интернетом",
    ],
  },
};

export const regions = {
  sitePENZA: {
    slug: "penza",
    name: "Пензенская область",
    daDataName: "Пензенская",
  },
  sitePERM: {
    slug: "perm",
    name: "Пермский край",
    daDataName: "Пермский",
  },
  siteORENBURG: {
    slug: "orenburg",
    name: "Оренбургская область",
    daDataName: "Оренбургская",
  },
  siteOREL: {
    slug: "orel",
    name: "Орловская область",
    daDataName: "Орловская",
  },
  siteSPB: {
    slug: "spb",
    name: "Санкт-Петербург и Ленинградская область",
    daDataName: "Ленинградская;Санкт-Петербург",
  },
  siteYANAO: {
    slug: "yanao",
    name: "Ямало-Ненецкий АО",
    daDataName: "ямало-ненецкий",
  },
  sitePSKOV: {
    slug: "pskov",
    name: "Псковская область",
    daDataName: "Псковская",
  },
  SiteALTAI: {
    slug: "altai",
    name: "Республика Алтай",
    daDataName: "Алтай",
  },
  siteYAR: {
    slug: "yar",
    name: "Ярославская область",
    daDataName: "Ярославская",
  },
  siteVLADIVOSTOK: {
    slug: "vladivostok",
    name: "Приморский край",
    daDataName: "Приморский",
  },
  siteBARNAUL: {
    slug: "barnaul",
    name: "Алтайский край",
    daDataName: "алтайский",
  },
  siteBURYATIA: {
    slug: "buryatia",
    name: "Республика Бурятия",
    daDataName: "Бурятия",
  },
  siteBELGOROD: {
    slug: "belgorod",
    name: "Белгородская область",
    daDataName: "Белгородская",
  },
  siteKOMI: {
    slug: "komi",
    name: "Республика Коми",
    daDataName: "Коми",
  },
  siteBRYANSK: {
    slug: "bryansk",
    name: "Брянская область",
    daDataName: "Брянская",
  },
  siteARH: {
    slug: "arh",
    name: "Архангельская область",
    daDataName: "Архангельская",
  },
  siteKARELIA: {
    slug: "karelia",
    name: "Республика Карелия",
    daDataName: "Карелия",
  },
  siteVOLOGDA: {
    slug: "vologda",
    name: "Вологодская область",
    daDataName: "Вологодская",
  },
  siteVLADIMIR: {
    slug: "vladimir",
    name: "Владимирская область",
    daDataName: "Владимирская",
  },
  siteVOLGOGRAD: {
    slug: "volgograd",
    name: "Волгоградская область",
    daDataName: "Волгоградская",
  },
  siteNORILSK: {
    slug: "norilsk",
    name: "Красноярский край (Норильск)",
    daDataName: "красноярский",
  },
  siteMORDOVIA: {
    slug: "mordovia",
    name: "Республика Мордовия",
    daDataName: "Мордовия",
  },
  siteMARIEL: {
    slug: "mariel",
    name: "Республика Марий Эл",
    daDataName: "Марий Эл",
  },
  siteKHAKASIA: {
    slug: "khakasia",
    name: "Республика Хакасия и Республика Тыва",
    daDataName: "Хакасия;Тыва",
  },
  siteIVANOVO: {
    slug: "ivanovo",
    name: "Ивановская область",
    daDataName: "Ивановская",
  },
  siteVORONEZH: {
    slug: "voronezh",
    name: "Воронежская область",
    daDataName: "Воронежская",
  },
  siteEAO: {
    slug: "eao",
    name: "Еврейская АО",
    daDataName: "Еврейская",
  },
  siteKAZAN: {
    slug: "kazan",
    name: "Республика Татарстан",
    daDataName: "Татарстан",
  },
  siteKALININGRAD: {
    slug: "kaliningrad",
    name: "Калининградская область",
    daDataName: "Калининградская",
  },
  siteKALUGA: {
    slug: "kaluga",
    name: "Калужская область",
    daDataName: "Калужская",
  },
  siteIRKUTSK: {
    slug: "irkutsk",
    name: "Иркутская область",
    daDataName: "Иркутская",
  },
  siteROSTOV: {
    slug: "rostov",
    name: "Ростовская область",
    daDataName: "Ростовская",
  },
  siteRYAZAN: {
    slug: "ryazan",
    name: "Рязанская область",
    daDataName: "Рязанская",
  },
  siteKAMCHATKA: {
    slug: "kamchatka",
    name: "Камчатский край",
    daDataName: "Камчатский",
  },
  siteSAMARA: {
    slug: "samara",
    name: "Самарская область",
    daDataName: "Самарская",
  },
  siteEKT: {
    slug: "ekt",
    name: "Свердловская область",
    daDataName: "Свердловская",
  },
  siteKUZBASS: {
    slug: "kuzbass",
    name: "Кемеровская область",
    daDataName: "Кемеровская область - Кузбасс",
  },
  siteSARATOV: {
    slug: "saratov",
    name: "Саратовская область",
    daDataName: "Саратовская",
  },
  siteSAKHALIN: {
    slug: "sakhalin",
    name: "Сахалинская область",
    daDataName: "Сахалинская",
  },
  siteKRASNODAR: {
    slug: "krasnodar",
    name: "Краснодарский край и Республика Адыгея",
    daDataName: "Краснодарский",
  },
  siteKRASNOYARSK: {
    slug: "krasnoyarsk",
    name: "Красноярский край (кроме Норильска)",
    daDataName: "красноярский",
  },
  siteTAMBOV: {
    slug: "tambov",
    name: "Тамбовская область",
    daDataName: "Тамбовская",
  },
  siteKIROV: {
    slug: "kirov",
    name: "Кировская область",
    daDataName: "Кировская",
  },
  siteSMOLENSK: {
    slug: "smolensk",
    name: "Смоленская область",
    daDataName: "Смоленская",
  },
  siteSTAVROPOL: {
    slug: "stavropol",
    name: "Ставропольский край",
    daDataName: "Ставропольский",
  },
  siteKOSTROMA: {
    slug: "kostroma",
    name: "Костромская область",
    daDataName: "Костромская",
  },
  siteLIPETSK: {
    slug: "lipetsk",
    name: "Липецкая область",
    daDataName: "Липецкая",
  },
  siteMAGADAN: {
    slug: "magadan",
    name: "Магаданская область",
    daDataName: "Магаданская",
  },
  siteKURGAN: {
    slug: "kurgan",
    name: "Курганская область",
    daDataName: "Курганская",
  },
  siteTVER: {
    slug: "tver",
    name: "Тверская область",
    daDataName: "Тверская",
  },
  siteKURSK: {
    slug: "kursk",
    name: "Курская область",
    daDataName: "Курская",
  },
  siteMSK: {
    slug: "msk",
    name: "Москва и область",
    daDataName: "Московская;Москва",
  },
  siteTOMSK: {
    slug: "tomsk",
    name: "Томская область",
    daDataName: "Томская",
  },
  siteTULA: {
    slug: "tula",
    name: "Тульская область",
    daDataName: "Тульская",
  },
  siteULN: {
    slug: "uln",
    name: "Ульяновская область",
    daDataName: "Ульяновская",
  },
  siteMURMANSK: {
    slug: "murmansk",
    name: "Мурманская область",
    daDataName: "Мурманская",
  },
  siteKHABAROVSK: {
    slug: "khabarovsk",
    name: "Хабаровский край",
    daDataName: "Хабаровский",
  },
  siteTYUMEN: {
    slug: "tyumen",
    name: "Тюменская область",
    daDataName: "Тюменская",
  },
  siteIZHEVSK: {
    slug: "izhevsk",
    name: "Удмуртская Республика",
    daDataName: "Удмуртская",
  },
  siteNOVOSIBIRSK: {
    slug: "novosibirsk",
    name: "Новосибирская область",
    daDataName: "Новосибирская",
  },
  siteCHUVASHIA: {
    slug: "chuvashia",
    name: "Чувашская Республика",
    daDataName: "Чувашская Республика",
  },
  siteOMSK: {
    slug: "omsk",
    name: "Омская область",
    daDataName: "Омская",
  },
  siteNNOV: {
    slug: "nnov",
    name: "Нижегородская область",
    daDataName: "Нижегородская",
  },
  siteHMAO: {
    slug: "hmao",
    name: "Ханты-Мансийский АО—Югра",
    daDataName: "ханты-мансийский автономный округ - югра",
  },
  siteCHELYABINSK: {
    slug: "chelyabinsk",
    name: "Челябинская область",
    daDataName: "Челябинская",
  },
  siteNOVGOROD: {
    slug: "novgorod",
    name: "Новгородская область",
    daDataName: "Новгородская",
  },
};

export const mixxes = {
  s: {
    title: "Mixx S",
    price: 200,
    subscriptions: "<strong>3 подписки на выбор</strong>",
    disabled: [
      "VK Play Cloud",
      "Lectr",
      "Gamersbase",
      "РТК Лицей",
      "Облако 128",
      "Доктис",
      "Firstars",
    ],
    black: [],
    litres: false,
  },
  m: {
    title: "Mixx M",
    price: 250,
    subscriptions: "<strong>4 подписки на выбор</strong>",
    disabled: [],
    black: [],
    litres: true,
  },
  l: {
    title: "Mixx L",
    price: 300,
    subscriptions: "<strong>6 подписок на выбор</strong>",
    disabled: [],
    black: ["+5% скидка «Выгодно вместе»", "Premier"],
    litres: true,
  },
  max: {
    title: "Mixx Max",
    price: 350,
    subscriptions:
      "<strong>Сервисы</strong> добавлять не нужно – они все включены в Mixx Max",
    disabled: [],
    black: "all",
    litres: true,
  },
};

/**
 * @type {{sourceText: string, translatedText: string}[]}
 */
export const glossary = [
  {
    sourceText: "ru",
    translatedText: "ru",
  },
  {
    sourceText: "ky",
    translatedText: "ky",
  },
  {
    sourceText: "uz",
    translatedText: "uz",
  },
  {
    sourceText: "tg",
    translatedText: "tg",
  },
  {
    sourceText: "en",
    translatedText: "en",
  },
  {
    sourceText: "es",
    translatedText: "es",
  },
  {
    sourceText: "ch",
    translatedText: "ch",
  },
  {
    sourceText: "t2",
    translatedText: "t2",
  },
  {
    sourceText: "Telegram",
    translatedText: "Telegram",
  },
  {
    sourceText: "Viber",
    translatedText: "Viber",
  },
  {
    sourceText: "WhatsApp",
    translatedText: "WhatsApp",
  },
  {
    sourceText: "Wink",
    translatedText: "Wink",
  },
  {
    sourceText: "more.tv",
    translatedText: "more.tv",
  },
  {
    sourceText: "TikTok",
    translatedText: "TikTok",
  },
  {
    sourceText: "Rutube",
    translatedText: "Rutube",
  },
  {
    sourceText: "ВКонтакте",
    translatedText: "Vkontakte",
  },
  {
    sourceText: "Одноклассники",
    translatedText: "Odnoklassniki",
  },
  {
    sourceText: "Optimum+",
    translatedText: "Optimum+",
  },
  {
    sourceText: "SOS",
    translatedText: "SOS",
  },
  {
    sourceText: "Aeromobile",
    translatedText: "Aeromobile",
  },
  {
    sourceText: "Cubio",
    translatedText: "Cubio",
  },
  {
    sourceText: "DTAG",
    translatedText: "DTAG",
  },
  {
    sourceText: "Ellipso",
    translatedText: "Ellipso",
  },
  {
    sourceText: "Emsat",
    translatedText: "Emsat",
  },
  {
    sourceText: "Global Networks",
    translatedText: "Global Networks",
  },
  {
    sourceText: "GlobalStar",
    translatedText: "GlobalStar",
  },
  {
    sourceText: "ICO",
    translatedText: "ICO",
  },
  {
    sourceText: "Inmarsat",
    translatedText: "Inmarsat",
  },
  {
    sourceText: "Iridium",
    translatedText: "Iridium",
  },
  {
    sourceText: "MCP",
    translatedText: "MCP",
  },
  {
    sourceText: "Onair",
    translatedText: "Onair",
  },
  {
    sourceText: "Oration",
    translatedText: "Oration",
  },
  {
    sourceText: "Seanet",
    translatedText: "Seanet",
  },
  {
    sourceText: "Thuraya",
    translatedText: "Thuraya",
  },
  {
    sourceText: "SMS",
    translatedText: "SMS",
  },
  {
    sourceText: "MMS",
    translatedText: "MMS",
  },
  {
    sourceText: "USSD",
    translatedText: "USSD",
  },
  {
    sourceText: "light",
    translatedText: "light",
  },
  {
    sourceText: "MiXX S",
    translatedText: "MiXX S",
  },
  {
    sourceText: "SIM",
    translatedText: "SIM",
  },
  {
    sourceText: "Black",
    translatedText: "Black",
  },
  {
    sourceText: "YouTube",
    translatedText: "YouTube",
  },
  {
    sourceText: "Twitch",
    translatedText: "Twitch",
  },
  {
    sourceText: "Discord",
    translatedText: "Discord",
  },
  {
    sourceText: "Premium",
    translatedText: "Premium",
  },
  {
    sourceText: "M",
    translatedText: "M",
  },
  {
    sourceText: "L",
    translatedText: "L",
  },
  {
    sourceText: "Power Bank",
    translatedText: "Power Bank",
  },
  {
    sourceText: "t2",
    translatedText: "t2",
  },
];

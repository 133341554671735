export const appTranslations = {
  loading: {
    ru: "Загрузка...",
    ky: "Жүктөө",
    tg: "Боркунӣ",
    uz: "Yuklash",
    zh: "加载中...",
    en: "Loading...",
    es: "Cargando...",
    fr: "Chargement...",
    ka: "იტვირთება...",
    vi: "Đang tải...",
    kk: "Жүктелуде...",
    hi: "लोड हो रहा है...",
    hy: "Բեռնվում է...",
    ar: "جاري التحميل...",
    tr: "Yükleniyor...",
    mn: "Ачааллаж байна...",
  },
};

<template>
  <div
    v-show="isShow"
    class="modal"
    :class="[getLanguage()]"
    tabindex="-1"
    aria-modal="true"
    role="dialog"
    id="modal-location"
  >
    <div class="modal-dialog">
      <transition name="fade-out">
        <div class="modal-content">
          <div class="modal__head">
            <button class="modal__close" @click="close()"></button>
          </div>
          <div class="modal__body">
            <div class="modal__title">Выбранный регион: {{ current }}</div>
            <div class="location">
              <div class="location-head">
                <div class="location-search">
                  <input v-model="userInput" type="text" />
                </div>
              </div>
              <div v-show="userInput" class="location-body">
                <div class="location-body__wrapper">
                  <div class="location-column">
                    <div class="location-section">
                      <div class="location-section__body">
                        <ul v-show="filtered?.length">
                          <li v-for="region of filtered" :key="region.id">
                            <button @click="confirm(region.id)">
                              {{ region.name }}
                            </button>
                          </li>
                        </ul>
                        <ul v-show="!filtered?.length">
                          <li>Регионы не найдены</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="!userInput" class="location-body">
                <div class="location-body__wrapper">
                  <div class="location-column">
                    <div class="location-section">
                      <div class="location-section__body">
                        <ul>
                          <li>
                            <button @click="confirm('siteMSK')">
                              Москва и область
                            </button>
                          </li>
                          <li>
                            <button @click="confirm('siteSPB')">
                              Санкт-Петербург и Ленинградская область
                            </button>
                          </li>
                          <li>
                            <button @click="confirm('siteCHELYABINSK')">
                              Челябинская область
                            </button>
                          </li>
                          <li>
                            <button @click="confirm('siteROSTOV')">
                              Ростовская область
                            </button>
                          </li>
                          <li>
                            <button @click="confirm('siteIRKUTSK')">
                              Иркутская область
                            </button>
                          </li>
                          <li>
                            <button @click="confirm('siteEKT')">
                              Свердловская область
                            </button>
                          </li>
                          <li>
                            <button @click="confirm('siteNNOV')">
                              Нижегородская область
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="item of sortedRegions"
                    :key="item.key"
                    class="location-column"
                  >
                    <div class="location-section">
                      <div class="location-section__head">
                        {{ item.key }}
                      </div>
                      <div class="location-section__body">
                        <ul>
                          <li v-for="region of item.items" :key="region.slug">
                            <button @click="confirm(region.id)">
                              {{ region.name }}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <transition name="fade">
    <div v-show="isShow" class="modal-backdrop"></div>
  </transition>
</template>

<script>
import { regions } from "@/constants";
import { mapMutations } from "vuex";
import useLanguage from "@/hooks/useLanguage";

export default {
  name: "ModalLocation",
  emits: ["close"],
  props: {
    text: {
      type: String,
    },
    to: {
      type: String,
    },
    current: {
      type: String,
    },
    isShow: {
      type: Boolean,
    },
  },
  watch: {
    isShow(value) {
      if (value) {
        this.beforeOpen();
      } else {
        this.afterClose();
      }
    },
  },
  data() {
    return {
      userInput: "",
      regions,
      sortedRegions: [],
    };
  },
  setup() {
    const { getLanguage } = useLanguage();
    return {
      getLanguage,
    };
  },
  computed: {
    filtered() {
      if (!this.userInput) {
        return;
      }
      const regexp = new RegExp(this.userInput, "gi");
      let searchableRegions = [];
      this.sortedRegions.forEach((el) => {
        searchableRegions = [...searchableRegions, ...el.items];
      });
      return searchableRegions.filter((el) => {
        regexp.lastIndex = 0;
        return regexp.test(el.name);
      });
    },
  },
  created() {
    this.normalizeRegions();
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setRegion: "region/setRegion",
    }),
    beforeOpen() {
      document.body.classList.add("modal-open");
      document.addEventListener("keyup", this.keyPressEscape);
    },
    afterClose() {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keyup", this.keyPressEscape);
    },
    confirm(id) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          region: id,
        },
      });
      this.close();
      setTimeout(() => {
        location.reload();
      }, 300);
    },
    keyPressEscape(e) {
      if (e.key == "Escape") {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    onNext() {
      this.close();
      this.$router.push(this.to);
    },
    normalizeRegions() {
      for (let key in this.regions) {
        const item = { ...this.regions[key], ...{ id: key } };
        const letter = this.regions[key].daDataName[0].toUpperCase();
        const find = this.sortedRegions.find((el) => el.key === letter);
        if (find) find.items.push(item);
        else {
          this.sortedRegions.push({ key: letter, items: [item] });
        }
        this.sortedRegions.sort((a, b) => (a.key > b.key ? 1 : -1));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: block;
  &:not(.ru) {
    .location-search {
      height: 0px;
      visibility: hidden;
    }
    .location-section__head {
      display: none;
    }
  }
  &-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    display: flex;
    min-height: 100%;
    margin: 0 auto;
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #1f2229;
    background-clip: padding-box;
    outline: 0;
    padding: 0 15px;
    padding-bottom: 35px;
    color: #fff;
    @include lg {
      background-color: rgb(31, 34, 41, 0.95);
    }
  }
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.8;
  }
  &__head {
    display: flex;
    justify-content: flex-end;
  }
  &__close {
    @include reset-button;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7911.2094a.709.709 0 0 0-1.0027 0L9 7.9978 1.2116.2094A.709.709 0 1 0 .2089 1.2121l7.7884 7.7884L.209 16.7889a.709.709 0 0 0 1.0027 1.0027L9 10.0032l7.7884 7.7884a.709.709 0 1 0 1.0027-1.0027l-7.7884-7.7884 7.7884-7.7884a.709.709 0 0 0 0-1.0027Z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    width: 55px;
    height: 55px;
    margin: 0 -15px;
    @include md {
      margin: 15px;
    }
    &::before {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: -1;
      cursor: default;
    }
  }
  &__title {
    font-family: var(--fontSecondary);
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    @include lg {
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      margin-top: -22px;
    }
  }
  &__body {
    @include lg {
      margin: -35px 35px;
    }
  }
}

.location {
  &-column {
    padding-right: 10px;
    @include lg {
      max-width: 252px;
      width: 100%;
      padding-bottom: 30px;
      padding-right: 34px;
    }
  }
  &-body {
    overflow-y: auto;
    overflow-x: clip;
    max-height: calc(100vh - 200px);
    @include lg {
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // max-height: 100vh;
      // max-height: calc(100vh - 200px);
    }
    @include xl {
    }
    @media (min-width: 1535px) and (min-height: 815px) {
      // max-height: calc(100vh - 200px);
    }
    @media (max-width: 1023px) {
      margin-top: 10px;
      max-height: 72vh;
    }
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      max-height: 100vh;
      @media (max-width: 1300px) {
        max-height: 174vh;
      }
      @media (max-width: 768px) {
        max-height: 200vh;
      }
      @media (max-width: 767px) {
        flex-wrap: nowrap;
        max-height: calc(100vh - 220px);
      }
    }
  }
  &-head {
    padding-top: 24px;
  }
  &-search {
    @include lg {
      margin-bottom: 42px;
    }
    input {
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.1018 14.1624C11.717 15.3101 9.9391 16 8 16c-4.4183 0-8-3.5817-8-8s3.5817-8 8-8 8 3.5817 8 8c0 1.939-.6899 3.717-1.8376 5.1018l3.8679 3.8679a.75.75 0 0 1 0 1.0606.75.75 0 0 1-1.0606 0l-3.8679-3.8679ZM14.5 8c0-3.5899-2.9101-6.5-6.5-6.5S1.5 4.4101 1.5 8s2.9101 6.5 6.5 6.5 6.5-2.9101 6.5-6.5Z' fill='%2365707B'/%3E%3C/svg%3E");
      background-color: #2d313c;
      background-repeat: no-repeat;
      background-position: 15px 50%;
      border-radius: 8px;
      border: none;
      width: 100%;
      height: 48px;
      font-size: 16px;
      line-height: 22px;
      padding: 0 44px;
      color: #fff;
      &::placeholder {
        color: #65707b;
        @include lg {
          color: #fff;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
      }
      @include lg {
        background-color: transparent;
        border-radius: 0;
        border-bottom: 2px solid #fff;
        max-width: 444px;
        padding: 0 44px 0 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.1018 14.1624C11.717 15.3101 9.9391 16 8 16c-4.4183 0-8-3.5817-8-8s3.5817-8 8-8 8 3.5817 8 8c0 1.939-.6899 3.717-1.8376 5.1018l3.8679 3.8679a.75.75 0 0 1 0 1.0606.75.75 0 0 1-1.0606 0l-3.8679-3.8679ZM14.5 8c0-3.5899-2.9101-6.5-6.5-6.5S1.5 4.4101 1.5 8s2.9101 6.5 6.5 6.5 6.5-2.9101 6.5-6.5Z' fill='%23ffffff'/%3E%3C/svg%3E");
        background-position-x: calc(100% - 20px);
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  &-section {
    padding-top: 24px;
    @include lg {
      padding-top: 0;
    }
    &__head {
      font-family: var(--fontSecondary);
      font-size: 22px;
      line-height: 140%;
      @include lg {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 2px;
      }
    }
    &__body {
      ul {
        @include reset-list;
        display: grid;
        gap: 8px;
        li {
          button {
            @include reset-button;
            font-size: 14px;
            line-height: 140%;
            text-align: left;
            @include lg {
            }
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 16px;
}

::-webkit-scrollbar-button {
  display: none;
}
</style>

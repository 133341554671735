<template>
  <div class="detail-options">
    <template v-if="tariff">
      <h3 class="detail-options-title">Включено в тариф</h3>
      <div class="detail-options-body">
        <div class="detail-options-body__grid detail-options-body__grid_2">
          <div class="detail-options-option lock">
            <h5>Тариф Не блокируется при минусе</h5>
            <p>
              Пользуйтесь минутами и ГБ в тарифе даже <br />при отрицательном
              балансе
            </p>
          </div>
          <div class="detail-options-option grey">
            <h5>Безлимитный трафик</h5>
            <TariffFeatures
              class="detail-options-features"
              :item="tariff"
              :is-detail="true"
            />
          </div>
        </div>
        <div class="detail-options-body__grid detail-options-body__grid_3">
          <div class="detail-options-option">
            <h5>SOS-ПАКЕТ</h5>
            <p>
              Если закончились деньги на счете – пользуйтесь бесплатно самыми
              необходимыми приложениями
            </p>
          </div>
          <div class="detail-options-option">
            <h5>Персональные предложения</h5>
            <p>
              Персональные предложения в Личном кабинете от умного помощника МИА
            </p>
          </div>
          <div class="detail-options-option">
            <h5>Мой помощник</h5>
            <p>Услуга "Мой помощник" бесплатно включена в тариф</p>
          </div>
        </div>
      </div>
    </template>
    <h3 class="detail-options-title">Выгода тарифов</h3>
    <div class="detail-options-body">
      <div class="detail-options-body__grid detail-options-body__grid_2">
        <div v-if="tariff" class="detail-options-option grey">
          <h5>Используйте с тарифом</h5>
          <h5 class="detail-options-option__wink">Wink. Optimum+</h5>
          <p>More.tv, тысячи фильмов, сериалов и 100 ТВ-каналов от Wink</p>
        </div>
        <div v-else class="detail-options-option lock">
          <h5>Тариф Не блокируется при минусе</h5>
          <p>
            Пользуйтесь минутами и ГБ в тарифе даже <br />при отрицательном
            балансе
          </p>
        </div>
        <div v-if="showFrozenBlock" class="detail-options-option frozen">
          <h5>ЦЕНА ЗАМОРОЗИТСЯ <br />ДО КОНЦА 2026 ГОДА</h5>
          <p>При подключении тарифа цена не изменится</p>
        </div>
      </div>
      <div class="detail-options-body__grid">
        <div class="detail-options-option mix">
          <h5>MiXX</h5>
          <p>
            Слушайте музыку, смотрите кино, пользуйтесь интернетом <br />
            и другими сервисами в одной подписке
          </p>
        </div>
      </div>
      <div class="detail-options-body__grid detail-options-body__grid_3">
        <div class="detail-options-option gb">
          <h5>ДЕЛИТЕСЬ <br />ГИГАБАЙТАМИ</h5>
          <p>С другими абонентами <br />t2 по всей стране</p>
        </div>
        <div class="detail-options-option">
          <h5>Безлимит на месенджеры <br />и соцсети</h5>
          <p>Общайтесь, не думая о том, что интернет-трафик скоро закончится</p>
        </div>
        <div class="detail-options-option calendar">
          <h5>Вечные <br />минуты и гБ</h5>
          <p>Остатки минут не сгорают, <br />используйте их когда захотите</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TariffFeatures from "./TariffFeatures.vue";
import { computed } from "vue";

defineProps({
  tariff: {
    type: Object,
    required: false,
  },
});

const showFrozenBlock = computed(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get("region") !== "siteSTAVROPOL";
});
</script>

<style lang="scss" scoped>
.detail-options {
  &-title {
    padding: var(--widthGapH) 0;
    @include md {
      padding-bottom: 16px;
    }
  }
  &-body {
    display: grid;
    gap: var(--widthGapV);
    &__grid {
      display: grid;
      gap: var(--widthGapV);
      &_2 {
        @include md {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      &_3 {
        @include md {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      .detail-options-option {
        min-height: 128px;
        @include md {
          min-height: 196px;
        }
      }
    }
  }
  &-features {
    &:deep() {
      grid-auto-flow: row;
      height: 100%;
      align-items: end;
      & .tariff-features__pic {
        order: -1;
        & li {
          background-color: var(--colorGrey);
        }
      }
    }
  }
  &-option {
    background-color: var(--colorWhite);
    border-radius: var(--radiusOuter);
    min-height: 152px;
    color: var(--colorBlack);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    padding: var(--widthPadding);
    box-shadow: 0px -10px 22px rgba(0, 0, 0, 0.03),
      0px 2px 16px rgba(0, 0, 0, 0.06);
    @include md {
      min-height: 160px;
    }
    &.lock {
      background-color: var(--colorLightBlue);
      color: var(--colorWhite);
      background-image: url(@/assets/detail/lock/m.webp);
      background-size: 110px auto;
      padding-right: 110px;
      @include md {
        background-image: url(@/assets/detail/lock/d.webp);
        background-size: 130px auto;
        padding-right: 130px;
      }
    }
    &.grey {
      background-color: var(--colorGrey);
    }
    &.frozen {
      background-color: var(--colorBlue);
      color: var(--colorWhite);
      background-image: url(@/assets/detail/frozen/m.webp);
      background-size: 110px auto;
      padding-right: 110px;
      @include md {
        background-image: url(@/assets/detail/frozen/d.webp);
        background-size: 226px auto;
        padding-right: 226px;
      }
    }
    &.mix {
      color: var(--colorWhite);
      background-image: url(@/assets/detail/mix/m.webp);
      background-size: cover;
      @include md {
        background-image: url(@/assets/detail/mix/d.webp);
      }
    }
    &.gb {
      background-image: url(@/assets/detail/gb/m.webp);
      background-size: 158px auto;
      padding-right: 158px;
      @include md {
        background-image: url(@/assets/detail/gb/d.webp);
        background-size: 194px auto;
        padding-right: 194px;
      }
    }
    &.calendar {
      background-image: url(@/assets/detail/calendar/m.webp);
      background-size: 158px auto;
      padding-right: 158px;
      @include md {
        background-image: url(@/assets/detail/calendar/d.webp);
        background-size: 143px auto;
        padding-right: 143px;
      }
    }
    h3 {
      font-size: var(--sizeHeading3);
      line-height: var(--lineHeightHeading3);
      font-weight: 800;
      font-family: var(--fontSecondary);
      text-transform: none;
    }
    &__wink {
      display: grid;
      align-items: center;
      padding-left: 50px;
      background-repeat: no-repeat;
      background-image: url(@/assets/detail/wink/m.webp);
      background-size: 36px;
      height: 36px;
      @include md {
        background-image: url(@/assets/detail/wink/d.webp);
        background-size: 48px;
        height: 48px;
        padding-left: 58px;
      }
    }
  }
}

[dir="rtl"] {
  .detail-options-option {
    background-position: 0 100%;
    &.lock {
      background-size: 110px auto;
      padding-left: 110px;
      padding-right: var(--widthPadding);
      @include md {
        background-image: url(@/assets/detail/lock/d-rtl.webp);
        background-size: 132px auto;
        padding-left: 132px;
        padding-right: var(--widthPadding);
      }
    }
    &.frozen {
      background-size: 110px auto;
      padding-left: 110px;
      padding-right: var(--widthPadding);
      @include md {
        background-image: url(@/assets/detail/frozen/d-rtl.webp);
        background-size: 131px auto;
        padding-left: 131px;
        padding-right: var(--widthPadding);
      }
    }
    &.mix {
      @include md {
        background-image: url(@/assets/detail/mix/d-rtl.webp);
      }
    }
    &.calendar {
      background-size: 158px auto;
      padding-left: 158px;
      padding-right: var(--widthPadding);
      @include md {
        background-image: url(@/assets/detail/calendar/d-rtl.webp);
        background-size: 183px auto;
        padding-left: 183px;
        padding-right: var(--widthPadding);
      }
    }
    &.gb {
      background-image: url(@/assets/detail/gb/m.webp);
      background-size: 158px auto;
      padding-left: 158px;
      padding-right: var(--widthPadding);
      @include md {
        background-image: url(@/assets/detail/gb/d-rtl.webp);
        background-size: 194px auto;
        padding-left: 194px;
        padding-right: var(--widthPadding);
      }
    }
  }
}
</style>

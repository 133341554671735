<template>
  <div class="promo-foreigner">
    <h3>
      Как подключить SIM-карту
      <br v-if="!breakpointsMobile.includes(screenSize)" />иностранному
      гражданину
    </h3>
    <button @click="scroll(`#foreigner`)">подробнее</button>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.promo-foreigner {
  background-color: var(--colorBlack);
  border-radius: var(--radiusOuter);
  color: var(--colorWhite);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding: var(--widthPadding);
  margin-top: var(--widthGapH);
  min-height: 128px;
  background-image: url(@/assets/foreigner/Top_559-296.webp);
  background-size: auto 100%;
  gap: 24px;
  @include md {
    min-height: 196px;
    background-image: url(@/assets/foreigner/Top_1136-870.webp);
  }

  button {
    @include reset-button;
    color: var(--colorBlack);
    font-size: 12px;
    padding: 8px 12px;
    background-color: var(--colorWhite);
    border-radius: 8px;
    font-weight: 800;
    font-family: var(--fontSecondary);
    text-transform: uppercase;
    line-height: 16px;
    @include md {
      font-size: 20px;
      padding: 16px 24px;
      border-radius: 16px;
      line-height: 20px;
    }
  }
}

[dir="rtl"] {
  .promo-foreigner {
    background-image: url(@/assets/foreigner/Top_559-296-rtl.webp);
    @include md {
      background-image: url(@/assets/foreigner/Top_1136-870-rtl.webp);
    }
  }
}
</style>

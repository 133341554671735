import { ref } from "vue";

/**
 * @typedef {'ky' | 'uz' | 'tg' | 'ru' | 'en' | 'es' | 'ch'} LanguageCode
 */

/**
 * @type {import("vue").Ref<LanguageCode>}
 */
const language = ref("ru");

/**
 * @type {{code: LanguageCode, name: string}[]}
 */
const languages = [
  {
    code: "ru",
    name: "ru",
  },
  {
    code: "ky",
    name: "kg",
  },
  {
    code: "uz",
    name: "uz",
  },
  {
    code: "tg",
    name: "tj",
  },
  {
    code: "en",
    name: "en",
  },
  {
    code: "es",
    name: "es",
  },
  {
    code: "zh",
    name: "ch",
  },
  {
    code: "fr",
    name: "fr",
  },
  {
    code: "ka",
    name: "ge",
  },
  {
    code: "vi",
    name: "vi",
  },
  {
    code: "kk",
    name: "kz",
  },
  {
    code: "hi",
    name: "hi",
  },
  {
    code: "hy",
    name: "am",
  },
  {
    code: "ar",
    name: "ar",
  },
  {
    code: "tr",
    name: "tr",
  },
  {
    code: "mn",
    name: "mn",
  },
];

export default function useLanguage() {
  /**
   * @param {LanguageCode} code
   */
  const setLanguage = (code) => {
    const targetLanguage = languages.find((l) => l.code === code);
    setDir(code);
    if (!targetLanguage) code = "ru";
    language.value = code;
  };

  const setDir = (code) => {
    if (code === "ar") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltl");
    }
  };
  /**
   * @return {LanguageCode}
   */
  const getLanguage = () => {
    return language.value;
  };

  return {
    setLanguage,
    getLanguage,
    languages,
  };
}

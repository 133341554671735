import useLanguage from "@/hooks/useLanguage";
import { unspanText } from "@/hooks/useTranslate";

const { getLanguage } = useLanguage();

/**
 * Возвращает текст из локального словаря для текущего языка если есть
 * иначе результат перевода с бека
 * @param {string} text
 * @param {import("@/hooks/useLanguage").LanguageCode} lang
 * @returns {string}
 */
const t = (text, lang) => {
  if (lang === "ru") return text;
  return dictionary[text][lang];
};
/**
 * Заменяет текст перевода на текст из локального словаря
 * для самых тяжелых случаев
 * @param {string[]} sourceTexts
 * @param {string[]} targetTexts
 * @returns {string[]}
 */
const fixT = (sourceTexts, targetTexts) => {
  const lang = getLanguage();

  sourceTexts.forEach((text, i) => {
    text = unspanText(text).trim();
    // заменяем все неразрывные пробелы обычным пробелом
    // eslint-disable-next-line
    text = text.replace(/ /g, " ");
    if (text in dictionary && dictionary[text][lang]) {
      targetTexts[i] = t(text, lang);
    }

    // в китайском убираем из перевода все символы 。
    if (lang === "zh") {
      targetTexts[i] = targetTexts[i].replace("。", "");
    }

    // в французском заменяем некоторые символы
    if (lang === "fr") {
      targetTexts[i] = targetTexts[i].replace("d &amp; apos; ", "d'");
      targetTexts[i] = targetTexts[i].replace("De L &amp; Apos; ", "de l'");
      targetTexts[i] = targetTexts[i].replace("D &amp; Apos; ", "d'");
    }

    console.log(`🇷🇺${text}\n🌍${targetTexts[i]}`);
  });
  return targetTexts;
};

/**
 * Словарик может включать в себя перевод на 1 язык,
 * для других будет использоваться ответ с бека
 * спецсимволы HTML нужно заменить на аналогичные для js (&nbsp будет \xA0)
 */
const dictionary = {
  Вконтакте: {
    ky: "VKontakte",
    tg: "VKontakte",
    uz: "VKontakte",
    zh: "VKontakte",
  },
  ВКонтакте: {
    ky: "VKontakte",
    tg: "VKontakte",
    uz: "VKontakte",
    zh: "VKontakte",
  },
  Одноклассники: {
    ky: "Odnoklassniki",
    tg: "",
    uz: "Odnoklassniki",
    zh: "Odnoklassniki",
    fr: "Odnoklassniki",
    ka: "Odnoklassniki",
    kk: "Odnoklassniki",
    tr: "Odnoklassniki",
    vi: "Odnoklassniki",
    hi: "Odnoklassniki",
    hy: "Odnoklassniki",
  },
  Вьетнам: {
    es: "Vietnam",
    zh: "越南",
    uz: "Vyetnam",
  },
  Киргизия: {
    zh: "吉尔吉斯",
    ky: "Кыргызстан",
  },
  Индия: {
    tg: "Хитой",
    ky: "Индия",
  },
  Азербайджан: {
    tg: "Озорбойҷон",
    ky: "Азербайжан",
  },
  "бесплатный трафик Telegram, WhatsApp, TikTok, ВКонтакте, Одноклассники": {
    zh: "免费流量 Telegram, WhatsApp, TikTok, VKontakte, Odnoklassniki",
  },
  "бесплатный трафик Telegram, WhatsApp, ВКонтакте, Одноклассники": {
    zh: "免费流量 Telegram, WhatsApp, TikTok, VKontakte, Odnoklassniki",
  },
  "Telegram, WhatsApp, TikTok, ВКонтакте, Одноклассники, Rutube, VK Видео, VK Клипы, VK Музыка":
    {
      zh: "Telegram, WhatsApp, TikTok, VKontakte, Odnoklassniki, Rutube, VK视频, VK短片, VK音乐",
    },
  "WhatsApp, Telegram, Вконтакте, Одноклассники, TikTok, Rutube, YouTube, VK Видео, VK Клипы, VK Музыка":
    {
      zh: "WhatsApp, Telegram, VKontakte, Odnoklassniki, TikTok, Rutube, YouTube, VK视频, VK短片, VK音乐",
    },
  YouTube: {
    zh: "油土鳖(YouTube)",
  },
  "VK Видео": {
    zh: "VK视频",
    es: "VK Video",
    fr: "VK Video",
    ka: "VK Video",
    kk: "VK Video",
    tr: "VK Video",
    vi: "VK Video",
    hi: "VK Video",
    hy: "VK Video",
  },
  "VK Клипы": {
    zh: "VK短片",
    fr: "VK Clips",
    ka: "VK Clips",
    kk: "VK Clips",
    tr: "",
    vi: "VK Clips",
    hi: "VK Clips",
    hy: "VK Clips",
  },
  "VK Музыка": {
    zh: "VK音乐",
    es: "VK Music",
    fr: "VK Music",
    ka: "VK Music",
    kk: "VK Music",
    tr: "",
    vi: "VK Music",
    hi: "VK Music",
    hy: "VK Music",
  },
  ТамТам: {
    zh: "TamTam",
    es: "TamTam",
    fr: "TamTam",
    ka: "TamTam",
    kk: "TamTam",
    tr: "",
    vi: "TamTam",
    hi: "TamTam",
    hy: "TamTam",
  },
  Кинопоиск: {
    zh: "Kinopoisk",
    es: "Kinopoisk",
    fr: "Kinopoisk",
    ka: "Kinopoisk",
    kk: "Kinopoisk",
    tr: "Kinopoisk",
    vi: "Kinopoisk",
    hi: "Kinopoisk",
    hy: "Kinopoisk",
  },
  Иви: {
    zh: "Ivi",
    es: "Ivi",
    en: "Ivi",
    fr: "Ivi",
    ka: "Ivi",
    kk: "Ivi",
    tr: "Ivi",
    vi: "Ivi",
    hi: "Ivi",
    hy: "Ivi",
  },
  "МОЙ ОНЛАЙН": {
    es: "MI ONLINE",
  },
  "МОЙ ОНЛАЙН+": {
    es: "MI ONLINE+",
  },
  BLACK: {
    fr: "",
    ka: "შავი",
    kk: "",
    tr: "",
    vi: "",
    hi: "ब्लैक",
    hy: "",
    mn: "Хар",
  },
  PREMIUM: {
    fr: "",
    ka: "პრემიუმი",
    kk: "",
    tr: "",
    vi: "",
    hi: "प्रीमियम",
    hy: "",
    mn: "Шимтгэл",
  },
  ИГРОВОЙ: {
    es: "GAMER",
    en: "GAMER",
    fr: "",
    ka: "თამაში",
    kk: "Ойын үшін",
    tr: "",
    vi: "Gói chơi game",
    hi: "गेमिंग के लिए",
    hy: "",
  },
  ГБ: {
    fr: "",
    ka: "GB",
    kk: "",
    tr: "",
    vi: "GB",
    hi: "GB",
    hy: "",
  },
  Купить: {
    es: "CONTRATAR",
    en: "GET IT FOR",
    fr: "",
    ka: "იყიდე",
    kk: "",
    tr: "Almak",
    vi: "Mua ngay",
    hi: "",
    hy: "",
    mn: "Худалдаж авах",
  },
  "к тарифу": {
    mn: "тариф нэмэгдэнэ",
  },
  "от 100 Мбит﻿/﻿с": {
    es: "desde 100 Mbp/s",
    en: "starting at 100 Mbp/s",
  },
  "Другие цены": {
    es: "Otras tarifas y precios",
  },
  "Другие условия": {
    es: "Otros términos y condiciones",
  },
  "В ПОЕЗДКАХ ПО РОССИИ": {
    es: "USO EN VIAJES POR RUSIA",
    en: "USE YOUR PLAN WHILE TRAVELING IN RUSSIA",
    ky: "РОССИЯ БОЮНЧА САПАРДА",
    uz: "ROSSIYADA SAYOHAT QILGANDA",
    mn: "Оросын нутаг дэвсгэрт аялахад",
  },
  "ЗАЩИТА ОТ СКРЫТЫХ ПОДПИСОК": {
    es: "PROTECCIÓN CONTRA SUSCRIPCIONES NO DESEADAS",
    en: "PROTECTION FROM UNWANTED SUBSCRIPTIONS",
  },
  "Безлимитный трафик на": {
    fr: "",
    ka: "შეუზღუდავი მოძრაობა",
    kk: "",
    tr: "",
    vi: "",
    hi: "",
    hy: "असीमित ट्रैफ़िक",
  },
  "Маркет Т2": {
    fr: "",
    ka: "",
    kk: "T2 НАРЫҒЫ",
    tr: "MARKET t2",
    vi: "CHỢ t2",
    hi: "",
    hy: "",
  },
  "Блокировка мошенников, антиспам и другие продукты безопасности в вашем тарифе":
    {
      mn: "Мэхлэгчдийг хаах, антиспам болон бусад аюулгүй байдлын үйлчилгээнүүд таны тарифанд багтсан",
    },
  "Telegram, WhatsApp, TikTok, ВКонтакте, Одноклассники, VK Мессенджер, Rutube, VK Видео, VK Клипы, VK Музыка":
    {
      mn: "Telegram, WhatsApp, TikTok, ВКонтакте, Одноклассники, VK Мессенджер, Rutube, VK Видео, VK Клипс, VK Музык",
    },
  "Остатки минут не сгорают,": {
    en: "Unused minutes and data never",
  },
  "используйте их когда захотите": {
    en: "expire—use them whenever you want",
  },
  "Подключайтесь сейчас, и цена не изменится никогда": {
    en: "Sign up now, and the price will never change",
  },
  "Интернет по выходным и праздникам": {
    zh: "周末及假期无限上网",
    ky: "Интернет дем алыш жана майрам күндөрү",
    uz: "Internat dam olish va bayram kunlari",
  },
  "ОСОБЕННОСТИ ТАРИФА BLACK": {
    zh: "BLACK资费特点",
    es: "CARACTERÍSTICAS DEL PLAN BLACK",
    en: "BLACK PLAN FEATURES",
  },
  "МЕЖДУНАРОДНЫЙ РОУМИНГ БЕЗ ДОПЛАТ": {
    zh: "无需额外费用的国际漫游",
    es: "ROAMING INTERNACIONAL SIN COSTOS ADICIONALES",
    en: "INTERNATIONAL ROAMING AT NO EXTRA COST",
  },
  "ВЫГОДНЫЕ ЗВОНКИ В БЛИЖНЕЕ ЗАРУБЕЖЬЕ": {
    zh: "近邻国家的优惠电话",
    es: "LLAMADAS ECONÓMICAS A PAÍSES VECINOS",
    en: "AFFORDABLE CALLS TO NEIGHBORING COUNTRIES",
  },
  "Исходящие международные звонки по специальной цене в страны СНГ и ближнего зарубежья":
    {
      zh: "以优惠价格拨打独联体和邻国的国际电话",
      es: "Llamadas internacionales salientes a precios especiales a los países de la CEI y regiones cercanas",
      en: "Enjoy special rates on international calls to CIS countries and nearby regions",
      fr: "Appels internationaux sortants à un prix spécial dans les pays de la Communauté des États indépendants et de l'étranger proche",
      ka: "გამავალი საერთაშორისო ზარები სპეციალური ფასით სНД-ს ქვეყნებში და ახლო უცხოეთში",
      kk: "ТМД елдеріне және көршілес елдерге арнайы бағамен шығыс халықаралық қоңыраулар",
      tr: "BDT ve komşu ülkelere özel fiyatla uluslararası aramalar",
      vi: "Cuộc gọi quốc tế đi với giá đặc biệt đến các nước SNG và khu vực lân cận",
      hi: "",
      hy: "",
    },
  "БЕЗЛИМИТНАЯ АРЕНДА POWER BANK": {
    zh: "无限量充电宝租赁",
    es: "ALQUILER ILIMITADO DE POWER BANKS",
    mn: "ХЯЗГААРГҮЙ ЦАХИЛГААН БАНКНЫ ТҮРЭЭС",
  },
  "Новинки кино, популярные сериалы и 100 ТВ‑каналов – смотрите одновременно на 5 устройствах":
    {
      zh: "在 5 台设备上同时观看新电影、流行电视剧和100个电视频道 ",
      es: "Estrenos de cine, series populares y 100 canales de TV: disfruta en hasta 5 dispositivos al mismo tiempo",
      en: "Watch the latest movies, popular series, and 100 TV channels on up to 5 devices simultaneously",
      fr: "Nouveautés cinéma, séries populaires et 100 TV canaux-regardez simultanément sur 5 appareils",
      ka: "კინოს ახალბედები, პოპულარული სერიალები და 100 ტელეარხი – უყურეთ ერთდროულ 5 მოწყობილობაზე",
      kk: "",
      tr: "Yeni filmler, popüler diziler ve 100 TV kanalı - aynı anda 5 cihazda izleyin",
      vi: "Phim mới, series phổ biến và 100 kênh TV – xem đồng thời trên 5 thiết bị",
      hi: "नई फ़िल्में, लोकप्रिय सीरीज़ और 100 टीवी चैनल्स - 5 डिवाइसेस पर एक साथ देखें",
      hy: "",
      mn: "Шинэ кино, алдартай телевизийн цуврал, 100 телевизийн суваг-5 төхөөрөмж дээр нэгэн зэрэг үзэх",
    },
  месяц: {
    fr: "",
    ka: "თვეში",
    kk: "",
    tr: "ay",
    vi: "",
    hi: "",
    hy: "",
  },
  "каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай",
    tg: "",
    uz: "Har 3-inchi oyda",
    zh: "每3个月份",
  },
  "на остальные номера России": {
    ky: "Россиядагы башка номерлерге",
    tg: "",
    uz: "Rossiyadagi boshqa raqamlarga",
    zh: "到其他俄罗斯手机号码",
    es: "a otros números de Rusia",
    en: "to all other numbers in Russia",
  },
  "на остальные номера России и": {
    zh: "分钟到其他俄罗斯手机号码和无限到俄罗斯手机t2号码",
    es: "a otros números de Rusia y llamadas ilimitadas a t2 Rusia",
    en: "to all other numbers in Russia and unlimited calls to t2 Russia",
  },
  "на другие мобильные номера России и": {
    zh: "到家庭所在地的其他俄罗斯手机号码和无限到俄罗斯手机t2号码",
    es: "a otros números móviles de la región de origen y llamadas ilimitadas a t2 Rusia",
    en: "to other mobile numbers in your home region and unlimited calls to t2 Russia",
  },
  "Прозрачные границы M": {
    ky: "АЧЫК ЧЕК АРАЛАР М",
    zh: "透明边界 M",
    es: "Fronteras Transparentes M",
    tg: "Сарҳадҳои шаффоф М",
    fr: "Bordures transparentes M",
    ka: "გამჭვირვალე საზღვრები მ",
    kk: "Мөлдір шекаралары M",
    tr: "",
    vi: "Biên giới minh bạch M",
    hi: "",
    hy: "",
  },
  "до 30 мин. в месяц; с": {
    es: "hasta 30 min/mes",
    zh: "每月到30分钟",
    tg: "то 30 дақ. дар як моҳ",
    uz: "oyiga 30 daqiqagacha",
  },
  "Прозрачные границы L": {
    ky: "АЧЫК ЧЕК АРАЛАР L",
    zh: "透明边界 L",
    es: "Fronteras Transparentes L",
    tg: "Сарҳадҳои шаффоф L",
    fr: "Bordures transparentes L",
    ka: "გამჭვირვალე საზღვრები ლ",
    kk: "Мөлдір шекаралары L",
    tr: "",
    vi: "Biên giới minh bạch L",
    hi: "",
    hy: "",
  },
  "руб./мин.": {
    tg: "руб/дақ",
    ky: "руб./мин",
    uz: "rubl/dakika",
    zh: "卢布/分钟",
    es: "₽/min",
    en: "₽/min",
  },
  "31-й минуты": {
    tg: "аз 31 дақиқа то ба охир расидани моҳ",
    zh: "从第31分钟 ",
    es: "A partir del minuto 31 hasta el final del mes ",
    en: "from the 31st minute",
  },
  "до конца месяца – 20": {
    tg: " ",
    es: " ",
    zh: " ",
  },
  безлимит: {
    zh: " ",
    es: " ",
    en: " ",
    fr: "",
    ka: "შეუზღუდავი",
    kk: "Шектеусіз",
    tr: "Limitsiz",
    vi: "",
    hi: "Không giới hạn",
    hy: "अनलिमिटेड",
  },
  "на общение и развлечения": {
    mn: "харилцаа, зугаацан",
  },
  "на t2 России": {
    zh: " ",
    es: " ",
    en: " ",
    fr: "vers des numéros T2 de la Russie",
    ka: "რუსეთის t2-ზე",
    kk: "Т2 Ресей бойынша",
    tr: "Rusyanın t2 numaralarına",
    vi: "đối với t2 Nga",
    hi: "रूसी t2 नंबर्स पर कॉल्स के लिए",
    hy: "",
    mn: " ОХУ-д t2-оор",
  },
  "Смотрите одновременно на 5 устройствах": {
    ky: "Бир эле учурда 5 түзмөктө көрүңүз",
    tg: "",
    uz: "Bir vaqtning o'zida 5 ta qurilmada tomosha qiling",
  },
  "ВЫДЕЛЕННАЯ ЛИНИЯ ДЛЯ ВАШИХ ВОПРОСОВ": {
    ky: "",
    tg: "",
    uz: "SAVOLLARINGIZUCHUN  ALOHIDA  LINIYA AJRATILGAN",
    zh: "专门的热线解答的问题",
    es: "LÍNEA DIRECTA PARA TUS CONSULTAS",
    en: "DEDICATED SUPPORT LINE",
    mn: "ТАНЫ АСУУДЛЫН ТУСГАЙ ШУГАМ",
  },
  "Операторы приоритетно принимают звонки по выделенной линии 711": {
    ky: "Операторлор артыкчылыктуу чалууларды атайын 711 линиясы боюнча кабыл алышат",
    tg: "",
    uz: "Operatorlar maxsus 711 liniyasidaustuvor qo'ng'iroqlarni qabul qilishadi",
    zh: "接线员优先接听专线 711",
    es: "Atención prioritaria a través de la línea directa 711",
    en: "Get priority assistance via the dedicated 711 line",
    mn: "711 дугаарт операторууд тандын дуудлагт онцлох анхаарал хандуулна",
  },
  "Бесплатная аренда power bank в салонах связи t2 и на станциях партнера на 3 дня":
    {
      ky: "Т2 салондорунда жана өнөктөш станцияларда 3 күнгө Power Bank бекер ижарага берилет",
      tg: "",
      uz: "Power Bankni t2 aloqa do'konlarida va hamkor stantsiyalarda 3 kunga bepul ijaraga olish",
      zh: "免费租用 power bank 在移动通讯服务网点 t2 并在合作伙伴站 3 一天",
      es: "Alquiler gratuito de power banks en las tiendas t2 y en estaciones asociadas durante 3 días",
      en: "Rent power banks for free at t2 stores and partner stations for up to 3 days",
      fr: "Location gratuite de power bank dans les salons de communication t2 et dans les stations partenaires pendant 3 jours",
      ka: "Power Bank-ის უფასო გაქირავება t2 კავშირგაბმულობის სალონებში და პარტნიორის სადგურებზე 3 დღის განმავლობაში",
      kk: "t2 байланыс дүкендерінде және серіктес станцияларында 3 күнге қуат банкін тегін жалға алу",
      tr: "3 gün boyunca t2 iletişim mağazalarında ve ortak istasyonlarda ücretsiz power bank kiralama",
      vi: "Thuê Power Bank miễn phí tại các cửa hàng kết nối t2 và trạm của đối tác trong 3 ngày",
      hi: "t2 संचार स्टोर्स और पार्टनर स्टेशनों में 3 दिनों के लिए मुफ्त में पावर बैंक लें",
      hy: "",
      mn: "T2 холбооны салон болон түнш станцуудад 3 хоногийн турш цахилгаан банк үнэгүй түрээслүүлнэ",
    },
  "Пользуйтесь минутами и ГБ в тарифе даже при отрицательном балансе": {
    zh: "使用手机套餐资费的分钟和GB，甚至余额为负数",
    es: "Disfruta de minutos y GB incluso con saldo negativo",
    ky: "Терс баланс болгондо да тарифте мүнөттөрдү жана ГБ колдонуңуз",
    uz: "Salbiy balans bo‘lsa ham, tarifdagi daqiqalar va GB’lardan foydalaning",
    en: "Enjoy your minutes and data even with a negative balance",
    mn: "Сөрөг үлдэгдэлтэй үед ч тарифын минут, ГБ-ээ ашиглах боломжтой",
  },
  "Пользуйтесь минутами и ГБ в тарифе даже": {
    ky: "Тарифте мүнөттөрдү жана ГБ балансыныз",
    tg: "",
    uz: "Salbiy qoldiqda ham tarifda daqiqalar",
    en: "Keep using your minutes and data",
    es: "Disfruta de minutos y GB",
    fr: "Utilisez des minutes et des gigaoctets dans le tarif même ",
    ka: "გამოიყენეთ წუთები და გბ ტარიფში თუნდაც",
    kk: "",
    tr: "",
    vi: "Sử dụng phút và GB trong gói cước ngay ",
    hi: "",
    hy: "",
    mn: "Тарифын минутан, гигабайтаа сөрөг үлдэгдэлтэй ",
  },
  "при отрицательном балансе": {
    ky: " терс учурда да колдонуңуз",
    tg: "",
    uz: " va GB-dan foydalaning",
    en: "even if your balance is negative",
    es: "incluso con saldo negativo",
    fr: "avec un solde négatif",
    ka: "უარყოფითი ბალანსით",
    kk: "",
    tr: "",
    vi: "cả khi số dư âm.",
    hi: "",
    hy: "",
    mn: "үед ч ашиглах боломжтой",
  },
  "Исходящие звонки на t2 России из популярных стран включены в тариф": {
    ky: "",
    tg: "",
    uz: "Mashhur mamlakatlardan T2 Rossiyaga qo'ng'iroqlar tarifga kiritilgan",
    zh: "从流行国家到俄罗斯t2号码的拨出电话包括在资费中",
    es: "Llamadas salientes desde países populares a números t2 Rusia incluidas en el plan",
    en: "Outgoing calls to t2 Russia numbers from popular countries are included in the plan",
    fr: "Les appels sortants vers T2 en Russie à partir de pays populaires sont inclus dans le tarif",
    ka: "გამავალი ზარები პოპულარული ქვეყნებიდან t2 რუსეთის ნომრებზე შედის ტარიფში",
    kk: "Тарифке танымал елдерден ресейлік t2-ге шығыс қоңыраулар кіреді",
    tr: "Popüler ülkelerden t2 Rusya numaralarına yapılan aramalar tarifeye dahildir",
    vi: "Cuộc gọi đi từ các quốc gia phổ biến đến số t2 tại Nga được bao gồm trong gói cước",
    hi: "लोकप्रिय देशों से रूसी t2 नंबर्स पर आउटगोइंग कॉल्स टैरिफ में शामिल हैं",
    hy: "",
  },
  "Исходящие звонки из популярных стран на номера t2 России включены в тариф": {
    ky: "Тарифке популярдуу өлкөлөрдөн t2 Россияга чыгуучу чалуулар кирет",
    tg: "",
    uz: "Mashhur mamlakatlardan t2 Rossiya raqamlariga chiquvchi qo'ng'iroqlar tarifga kiritilgan",
    zh: "从流行国家到俄罗斯t2号码的拨出电话包括在资费中",
    es: "Llamadas salientes desde países populares a números t2 Rusia incluidas en el plan",
    en: "Outgoing calls to t2 Russia numbers from popular countries are included in the plan",
    mn: "Алдартай орнуудаас Оросын t2 дугаар руу гарч буй дуудлага нь тарифт багтсан болно",
  },
  "Если закончились деньги на счете – пользуйтесь бесплатно самыми необходимыми приложениями":
    {
      ky: "",
      tg: "",
      uz: "Hisobingizda pul tugagan bo‘lsa ham, eng zarur xizmatlardan bepul foydalaning",
      mn: "Дансны мөнгө дууссан тохиолдолд хамгийн шаардлагатай апп-уудыг үнэгүй ашиглах боломжтой",
    },
  "SOS-ПАКЕТ": {
    mn: "ЯАРАЛТЫН БАГЦ",
  },
  "При подключении тарифа цена не изменится": {
    ky: "Тарифте мүнөттөрдү жана ГБ-ды  балансыныз терс учурунда да колдонуңуз",
    tg: "",
    uz: "Tarifga ulanishda narx o’zgarmaydi",
    zh: "连接资费时，价格不会改变",
    es: "Al contratar un plan, el precio no cambiará",
    en: "Sign up now, and the price will remain the same",
  },
  "Если не пользоваться SIM‑картой": {
    ky: "Эгер сиз SIM картаны колдонбосоңуз",
    tg: "",
    uz: "Agar siz SIM kartadan foydalanmasangiz",
    zh: "如果不使用SIM卡",
    en: "Conditions for unused SIM cards",
    es: "Condiciones en caso de no utilizar la tarjeta SIM",
    mn: "Хэрэв SIM карт хэрэглэхгүй бол",
  },
  "Минуты не тратятся, а ГБ расходуются из тарифа": {
    ky: "",
    tg: "",
    uz: "Daqiqalar behuda ketmaydi va GB-lar tarifdan sarflanadi",
  },
  "Делитесь интернет-трафиком с другими абонентами t2 по всей стране": {
    tg: "",
    ky: "Өлкө боюнча башка t2 абоненттери менен интернет-трафикти бөлүшүңүз",
    uz: "Internet-trafikni butun mamlakat bo‘ylab boshqa t2 abonentlari bilan bo‘lishing",
  },
  "60 ГБ каждый 3-й": {
    fr: "",
    ka: "60 GB ყოველ 3",
    kk: "Әр 3 айдан кейін +60 ГБ",
    tr: "",
    vi: "",
    hi: "",
    hy: "",
  },
  "+60 ГБ каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай +60 ГБ",
    tg: "",
    uz: "Har 3-inchi oyda +60 GB",
  },
  "Оставшиеся минуты и ГБ не сгорают": {
    ky: "",
    tg: "",
    uz: "Qolgan daqiqalar va GB-lar yonmaydi",
    zh: "剩余的分钟和GB不燃烧",
    es: "Los minutos y GB restantes no caducan",
    en: "Unused minutes and data never expire",
  },
  "Слушайте музыку, смотрите кино, пользуйтесь интернетом": {
    ky: "Бир жазылууда музыка угуңуз, кино көрүңүз, Интернетти жана",
    tg: "",
    uz: "Bitta obunada musiqa tinglang, filmlar tomosha qiling, Internet",
    zh: "在一次订阅听音乐、看电影、使用互联网和其他服务",
    es: "Disfruta música, cine, internet y otros servicios en una sola suscripción",
    en: "Enjoy music, movies, internet, and more in one subscription",
    mn: "Хөгжим сонсох, кино үзэх, интернет ашиглах",
  },
  "и другими сервисами в одной подписке": {
    ky: "башка кызматтарды колдонуңуз",
    tg: "",
    uz: "va boshqa xizmatlardan foydalaning",
    zh: "使用互联网和其他服务",
    en: " ",
    es: " ",
    mn: "болон нэг захиалгын бусад үйлчилгээ",
  },
  "+35 ГБ каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай +35 ГБ",
    tg: "",
    uz: "Har 3-inchi oyda +35 GB",
  },
  "Условия настройки тарифов": {
    ky: "",
    tg: "",
    uz: "Tariflarni belgilash shartlari",
    zh: "资费设置条件",
    es: "Condiciones para configurar los planes",
  },
  "Применение рассрочки списания абонентской платы": {
    ky: "",
    tg: "",
    uz: "Abonent to‘lovlarini bo‘lib-bo‘lib to‘lashni qo‘llash",
    es: "Aplicación de cuotas para el pago de la tarifa mensual",
    en: "Payment installment options",
  },
  "Правила списания абонентской платы и оказания услуг": {
    ky: "",
    tg: "",
    uz: "Abonent to‘lovlarini undirish va xizmatlar ko‘rsatish qoidalari",
    zh: "扣除订阅费用和提供服务的规则",
    es: "Reglas para el cobro de tarifas y provisión de servicios",
    en: "Rules for billing and service provision",
  },
  "+10 ГБ каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай +10 ГБ",
    tg: "",
    uz: "Har 3-inchi oyda +10 GB",
  },
  "+25 ГБ каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай +25 ГБ",
    tg: "",
    uz: "Har 3-inchi oyda +25 GB",
  },
  "ЗАМОРОЗИЛИ ЦЕНУ": {
    ky: "БААНЫ ТОҢДУРУУ",
    tg: "",
    uz: "Tarifga ulanishda narx",
    zh: "价格被冻结至2026年底",
    es: "PRECIO CONGELADO HASTA FINES DE 2026",
    en: "PRICE LOCKED UNTIL THE END OF 2026",
  },
  "ЦЕНА ЗАМОРОЗИТСЯ": {
    ky: "БААСЫ 2026-ЖЫЛДЫН АЯГЫНА ЧЕЙИН ТОНДУРУП КАЛАТ",
    tg: "",
    uz: "NARX 2026-YIL OXIRIGACHA O'ZGARMAYD",
    zh: "价格被冻结至2026年底",
    en: "Price locked until the end of 2026",
    es: "Congelamos el precio hasta finales de 2026",
    fr: "LE PRIX VA GELER",
    ka: "ფასი გაიყინება ",
    kk: "",
    tr: "",
    vi: "GIÁ SẼ ĐÓNG BĂNG",
    hi: "कीमत एक ही रहेगी",
    hy: "",
  },
  "ДО КОНЦА 2026 ГОДА": {
    ky: " ",
    tg: "",
    uz: " ",
    zh: " ",
    en: " ",
    es: " ",
    fr: "JUSQU'À LA FIN DE 2026",
    ka: "2026 წლის ბოლომდე",
    kk: "",
    tr: "",
    vi: "ĐẾN CUỐI NĂM 2026",
    hi: "2026 के अंत तक",
    hy: "",
  },
  мес: {
    ky: "ай",
    tg: "",
    uz: "",
    zh: "月份",
  },
  Интернет: {
    ky: "Интернет",
    tg: "",
    uz: "",
    mn: "Интернет",
  },
  Звонки: {
    mn: "Звонки",
  },
  Россия: {
    ky: "Россия",
    tg: "",
    uz: "",
  },
  "Подробная информация на сайте t2.ru": {
    ky: "Толук маалымат t2.ru сайтында",
    tg: "",
    uz: "",
  },
  "Узбекистан – 5 руб./мин.": {
    ky: "Өзбекстан – 5 рубль/мүнөт.",
    tg: "",
    uz: "O'zbekiston - 5 rubl / min.",
    mn: "Узбекистан - 5 рубль/я.",
  },
  "до 30 мин. в сутки; с 31-й минуты – 6,9 руб./мин.": {
    ky: "30 мүнөткө чейин. күнүнө; 31-мүнөттөн баштап – 6,9 руб/мин.",
    tg: "",
    uz: "30 daqiqagacha. kuniga; 31-daqiqadan boshlab - 6,9 rubl / min.",
    mn: "30 минут хүртэл. өдөрт; 31 дэх минутаас - 6.9 рубль/я.",
  },
  "Таджикистан – 8 руб./мин.": {
    ky: "Тажикстан – 8 рубль/мүнөт.",
    tg: "",
    uz: "Tojikiston - 8 rubl / min.",
    mn: "Тажикистан-8 рубль/я.",
  },
  "до 30 мин. в месяц; с 31-й минуты до конца месяца – 20 руб./мин.": {
    ky: "30 мүнөткө чейин. айына; 31-мүнөттөн айдын аягына чейин – 20 руб/мин.",
    tg: "",
    uz: "30 daqiqagacha. oyiga; 31-daqiqadan oyning oxirigacha - 20 rubl / min.",
    mn: "30 минут хүртэл. сард; 31 дэх минутаас тоглолтын төгсгөл хүртэл-20 рубль/я.",
  },
  "More.tv, тысячи фильмов, сериалов и 100 ТВ-каналов от Wink": {
    ky: "More.tv, Wink-тен 100 ТВ каналдар, миңдеген фильмдер жана сериалдар ",
    tg: "",
    uz: "More.tv, minglab filmlar, seriallar va Wink-dan 100 ta televizion kanallar",
    tr: "More.tv, Wink'ten binlerce film, dizi ve 100 TV kanalı",
    mn: "More.tv, Wink-аас мянганы кино, цувралууд болон 100+ ТВ суваг",
  },
  "3 месяца домашнего интернета бесплатно + 50 ГБ мобильного каждый месяц": {
    ky: "3 ай акысыз үй интернет + ай сайын 50 ГБ мобилдик",
    tg: "",
    uz: "3 oylik uy interneti bepul + har oyda 50 GB mobil interneti",
  },

  "ПРОЗРАЧНЫЕ ГРАНИЦЫ ": {
    ky: "АЧЫК ЧЕК АРАЛАР ",
    tg: "",
    uz: "",
  },
  "МОЙ РАЗГОВОР": {
    ky: "МЕНИН МАЕГИМ",
    tg: "",
    uz: "",
    en: "MY TALK",
    fr: "",
    ka: "",
    kk: "",
    tr: "Benim konuşmam",
    vi: "",
    hi: "",
    hy: "",
  },
  "Безлимит на t2 России": {
    ky: "Чексиз Россиядагы t2 номерлерге",
    tg: "",
    uz: "",
  },
  Подробнее: {
    ky: "Кененирээк маалымат",
    tg: "",
    uz: "",
    es: "MÁS INFORMACIÓN",
    mn: "Дэлгэрэнгүй",
  },
  "МЕНЯЙТЕ МИНУТЫ И ГБ": {
    zh: "交换分钟和GB",
    es: "INTERCAMBIA MINUTOS Y GB",
    en: "EXCHANGE MINUTES AND DATA",
    ky: "МҮНӨТТӨРДҮ ЖАНА ГБНЫ ӨЗГӨРТҮҢҮЗ",
    uz: "DAQIQALAR VA GB’LARNI ALMASHTIRING",
    mn: "МИНУТ, ГБ-ЭЭ СОЛИХ",
  },
  "На скидки, кино, сервисы": {
    zh: "交换分钟和GB的折扣，电影和服务",
    ky: "Арзандатуулага, тасмаларга, кызматтарга",
    es: "Por descuentos, películas o servicios",
    en: "Trade your unused minutes and data for discounts, movies, or additional services",
    fr: "pour les réductions, les films, les services",
    ka: "ფასდაკლებისთვის, ფილმებისთვის, სერვისებისთვის",
    kk: "Жеңілдіктер, фильмдер, қызметтер үшін",
    tr: "",
    vi: "Để lấy giảm giá, xem phim, và sử dụng các dịch vụ",
    hi: "",
    hy: "",
    mn: "Хямдрал, кино, үйлчилгээнд ашиглах",
  },
  "ЗАМОРАЖИВАЕМ ЦЕНУ НАВЕЧНО": {
    zh: "永远冻结价格",
    en: "PRICE LOCKED FOREVER",
  },
  "ДЕЛИТЕСЬ ГИГАБАЙТАМИ": {
    zh: "共享千兆字节",
    uz: "GB’LARNI ULASHING",
    es: "COMPARTE GIGAS",
    en: "SHARE YOUR DATA ALLOWANCE",
    fr: "SE DIVISER GIGAOCTETS",
    ka: "გააზიარეთ გიგაბაიტი",
    kk: "ГИГАБАЙТАР МЕНЕН БӨЛІСУ",
    tr: "GİGABAYTLAR PAYLAŞMAK",
    vi: "CHIA SẺ GIGABYTE",
    hi: "",
    hy: "",
  },
  ДЕЛИТЕСЬ: {
    zh: "共享千兆字节",
    uz: "GB’LARNI ULASHING",
    es: "COMPARTE GIGAS",
    en: "SHARE YOUR DATA ALLOWANCE",
  },
  ГИГАБАЙТАМИ: {
    zh: " ",
    uz: " ",
    es: " ",
    en: " ",
  },
  "С другими абонентами": {
    zh: " ",
    es: "Con otros abonados de t2 en todo el país",
    en: "With other t2 subscribers across the country",
  },
  "t2 по всей стране": {
    zh: " ",
    es: " ",
    en: " ",
  },
  Вечные: {
    zh: "无尽的分钟和GB",
    es: "MINUTOS Y GB ACUMULABLES",
    en: "CARRY OVER MINUTES AND DATA",
  },
  "минуты и гБ": {
    zh: " ",
    es: " ",
    en: " ",
  },
  "Безлимит на месенджеры": {
    zh: "沟通，而不考虑互联网流量将很快结束的事实 永恒",
    es: "MENSAJERÍA Y REDES SOCIALES ILIMITADAS",
    en: "UNLIMITED MESSAGING AND SOCIAL MEDIA",
    fr: "",
    ka: "ულიმიტო მესინჯერები და სოციალური ქსელები",
    kk: "",
    tr: "",
    vi: "KHÔNG GIỚI HẠN CHO CÁC ỨNG DỤNG NHẮN TIN ",
    hi: "",
    hy: "",
  },
  "и соцсети": {
    zh: " ",
    es: " ",
    en: " ",
    fr: "",
    ka: "სოციალური ქსელები",
    kk: "",
    tr: "",
    vi: "VÀ MẠNG XÃ HỘI",
    hi: "",
    hy: "",
  },
  "Общайтесь, не думая о том, что интернет-трафик скоро закончится": {
    zh: " ",
    es: "Comunícate sin preocuparte por quedarte sin tráfico de internet",
    en: "Stay connected without worrying about running out of data",
  },
  "Выгода тарифов": {
    zh: "资费优点",
    es: "Ventajas de los planes",
    en: "Plan Benefits",
    mn: "ТАРИФЫН АШИГ",
  },
  "ВКЛЮЧЕНО В ТАРИФ": {
    mn: "ТАРИФТ БАГТСАН",
  },
  "Услуги, включенные в тариф": {
    zh: "包括在资费套餐中的服务",
    es: "Servicios incluidos en el plan",
    en: "Included services",
  },
  "Домашний интернет": {
    zh: "家庭互联网",
    en: "Home broadband",
  },
  "Домашний интернет:": {
    zh: "家庭互联网",
    en: "Home broadband",
    fr: "",
    ka: "სახლის ინტერნეტი:",
    kk: "Үй интернет:",
    tr: "",
    vi: "Internet gia đình",
    hi: "घरेलू इंटरनेट:",
    hy: "",
    mn: "Гэрийн интернет:",
  },
  "от 100 Мбит/с": {
    fr: "à partir de 100 mégabits par seconde",
    ka: "100 მბიტ/წმ-დან.",
    kk: "",
    tr: "100 Mbps-den",
    vi: "",
    hi: "100 Mbit/s से",
    hy: "",
    mn: "100 Мбит/с-ээс эхлэн",
  },
  "ВЕЗДЕ ОНЛАЙН": {
    zh: "网上在各处",
    es: "ONLINE EN TODAS PARTES",
  },
  "МАРКЕТ t2": {
    zh: "t2网店",
    en: "t2 MARKET",
    uz: "T2 MARKET",
    mn: "T2 ЗАХ ЗЭЭЛ",
  },
  Сообщения: {
    zh: "短信",
    es: "Mensajes",
  },
  минут: {
    zh: "分钟",
  },
  "ПРЕИМУЩЕСТВА ТАРИФА": {
    zh: "资费优点",
    es: "VENTAJAS DEL PLAN",
    en: "PLAN BENEFITS",
    ky: "ТАРИФТИН АРТЫКЧЫЛЫГЫ",
  },
  "Пользуйтесь минутами и ГБ в поездках как дома": {
    zh: "在旅行中使用分钟和GB，就像在家里一样",
    es: "Utiliza minutos y GB en tus viajes por Rusia como si estuvieras en casa",
    en: "Enjoy your minutes and data nationwide, just like at home",
    ky: "Сапарда үйдө жүргөндөй мүнөттөрүңүздү жана ГБңызды колдонуңуз",
    uz: "Daqiqalar va GB’larni uyda bo‘lgandek ishlating",
    fr: "Utilisez vos minutes et vos gigaoctets en voyage comme vous le feriez à la maison",
    ka: "გამოიყენეთ წუთები და GB როგორც სახლში",
    kk: "Үйдегідей сапарларда минуттар мен ГБ пайдаланыңыз",
    tr: "Dakikalarınızı ve GB'ınızı evdeymiş gibi hareket halindeyken kullanın",
    vi: "Sử dụng phút và GB khi đi du lịch như ở nhà.",
    hi: "",
    hy: "",
  },
  "УПРАВЛЯЙТЕ ТАРИФОМ": {
    zh: "管理您的资费套餐",
    es: "GESTIONA TU PLAN",
    en: "MANAGE YOUR PLAN",
    ky: "ТАРИФИҢИЗДИ БАШКАРЫҢЫЗ",
  },
  "Продавайте и покупайте ГБ, минуты и SMS": {
    ky: "ГБ, мүнөттөрдү жана SMS сатыңыз жана сатып алыңыз",
    tg: "",
    uz: "GB-lar, daqiqalar va SMS-larni sotish va sotib olish",
    en: "Sell and buy GB, minutes and SMS",
    zh: "出售和购买GB，分钟和短信",
    es: "Compra y vende GB, minutos y SMS",
    mn: "ГБ, минут, SMS-ийг худалдаж авах/зарах",
  },
  "3 месяца домашнего интернета бесплатно": {
    zh: "3个月免费家庭互联网",
    es: "3 meses de internet en casa gratis",
    en: "3 months of free broadband internet",
  },
  Тарифы: {
    zh: "手机套餐",
    es: "PLANES",
    en: "PLANS",
    mn: "ТАРИФУУД",
  },
  "ЧЕСТНЫЕ ТАРИФЫ": {
    zh: "公平的资费套餐",
    es: "PLANES TRANSPARENTES",
    en: "TRANSPARENT FEES",
    ky: "АДИЛЕТТҮҮ БААЛАР",
    mn: "ҮНЭН ТАРИФУУД",
  },
  "РАСХОДЫ ЗА ГРАНИЦЕЙ": {
    zh: "国外费用",
  },
  "СВЯЗЬ БЕЗ МИНУСОВ": {
    zh: "无负余额的通信",
    es: "CONECTIVIDAD SIN INTERRUPCIONES",
    en: "CONNECTION WITHOUT INTERRUPTIONS",
    ky: "КЕМЧИЛИКСИЗ БАЙЛАНЫШ",
    uz: "SALBIY BALANSSIZ ALOQA",
    fr: "COMMUNICATION SANS SOLDES NÉGATIFS",
    ka: "კომუნიკაცია მინუსების გარეშე",
    kk: "КЕМШІЛІКТЕРІ ЖОҚ БАЙЛАНЫС",
    tr: "EKSİKSİZ İLETİŞİM",
    vi: "KẾT NỐI KHÔNG ÂM",
    hi: "",
    hy: "",
  },
  "Тариф Не блокируется при минусе": {
    ch: "手机套餐资费余额为负数的时，不会被冻结",
    es: "EL PLAN NO SE BLOQUEA CON SALDO NEGATIVO",
    en: "NO BLOCKING WITH A NEGATIVE BALANCE",
    fr: "LE TARIF N'EST PAS BLOQUÉ AVEC UN SOLDE NÉGATIF  ",
    ka: "მინუსის შემთხვევაში ტარიფი არ იბლოკება",
    kk: "",
    tr: "EKSİ DURUMDA TARİFE ENGELLENMEZ",
    vi: "GÓI CƯỚC KHÔNG BỊ KHÓA KHI ÂM TIỀN",
    hi: "",
    hy: "",
    mn: "ТАРИФ СӨРӨГ ҮЛДЭГДЭЛТЭЙ ҮЕД Ч ГҮЙЦЭТГЭГДЭНЭ",
  },
  "Делитесь интернет‑трафиком с другими абонентами t2 по всей стране": {
    zh: "与其他t2用户分享互联网流量",
    es: "Comparte tráfico de internet con otros abonados de t2 en todo el país",
    en: "Share your data allowance with other t2 subscribers across the country",
  },
  "НАСТРОЙКА ТАРИФА": {
    zh: "设置资费套餐",
    es: "CONFIGURACIÓN DEL PLAN",
    en: "CUSTOMIZE YOUR PLAN",
  },
  "Выбирайте количество гигабайтов и минут, подключайте полезные сервисы": {
    zh: "选择GB和分钟数量，连接有用的服务",
    es: "Elige la cantidad de gigabytes y minutos, y añade servicios útiles",
  },
  "АБОНЕМЕНТЫ НА СВЯЗЬ": {
    zh: "移动通信订阅",
    es: "SUSCRIPCIÓN ANTICIPADA",
    en: "ADVANCE SUBSCRIPTION",
  },
  "Если закончились деньги на счете – пользуйтесь бесплатно самыми необходимыми сервисами":
    {
      zh: "如果手机账户余额用完了，可以免费使用最必要的服务",
      es: "Si te quedas sin saldo, sigue accediendo gratuitamente a los servicios más esenciales",
      en: "If you run out of money in your account, you can still use essential services for free",
      fr: "Si vous n'avez plus d'argent sur votre solde, utilisez gratuitement les services les plus nécessaires",
      ka: "თუ თქვენს ანგარიშზე თანხა ამოგეწურებათ, გამოიყენეთ ყველაზე აუცილებელი სერვისები უფასოდ",
      kk: "Есептік жазбаңызда ақша таусылған болса, ең қажетті қолданбаларды тегін пайдаланыңыз",
      tr: "Hesabınızda paranız biterse, en temel uygulamaları ücretsiz kullanın",
      vi: "Nếu tài khoản của bạn hết tiền – bạn vẫn có thể sử dụng miễn phí các ứng dụng cần thiết nhất",
      hi: "अगर आपके खाते में पैसे ख़त्म हो जाएँ, तो सबसे ज़रूरी एप्लिकेशन्स का मुफ्त में उपयोग करें",
      hy: "",
    },
  "Больше никаких случайностей – для подключения подписки понадобится код подтверждения":
    {
      zh: "不再发生意外 – 需要一个确认码来激活订阅",
      es: "Sin sorpresas: se requiere un código de confirmación para activar cualquier suscripción",
      en: "No more unexpected charges—subscriptions require confirmation codes to activate",
      uz: "Tasodifiy ulanishlar endi yo‘q – obuna faqat tasdiqlash kodi bilan faollashtiriladi",
    },
  "БЕЗЛИМИТНЫЙ ТРАФИК": {
    zh: "免费流量到",
    en: "UNLIMITED DATA",
    mn: "ХЯЗГААРГҮЙ ТРАФИК",
  },
  "Безлимитный трафик": {
    zh: "免费流量到",
    en: "UNLIMITED DATA",
  },
  "Тариф дешевле, если оплачивать несколько месяцев сразу": {
    zh: "一次支付几个月的手机资费更便宜",
    es: "El plan es más barato si pagas varios meses por adelantado",
    en: "Save more when you pay for several months in advance",
  },
  "Персональные предложения": {
    zh: "个人报价",
    mn: "ХУВЬ ХҮНИЙ СОНГОЛТУУД",
  },
  "Персональные предложения в Личном кабинете от умного помощника МИА": {
    zh: "来自智能助手 MIA 在个人帐户中的个人报价",
    ky: "МИА акылдуу жардамчысынын жеке кабинетиңизде жеке сунушу",
    mn: "Миа ухаалаг туслахын хувийн кабинет дахь танд зориулсан онцгой саналууд",
  },
  'Услуга "Мой помощник" бесплатно включена в тариф': {
    zh: "“我的助手”服务免费包含在资费中",
    mn: '"Миний туслах" үйлчилгээ таны тарифанд ҮНЭГҮЙ багтсан',
  },
  "Используйте с тарифом": {
    zh: "与资费一起使用",
    mn: "ТАРИФТАЙ ХАМТ АШИГЛАХ",
  },
  "Мой помощник": {
    ky: "МЕНИН ЖАРДАМЧЫМ",
  },
  "₽/мес": {
    zh: "₽/月份",
    es: "₽/mes",
  },
  "₽/месяц": {
    zh: "₽/月份",
    es: "₽/mes",
  },
  "Как подключить SIM-карту": {
    ky: "ЧЕТ ЭЛДИК ЖАРАН ҮЧҮН SIM-КАРТА КАНТИП",
    tg: "SIM-КОРТРО БА ШАҲРВАНДИ ХОРИҶӢ ЧӢ ТАВР ФАЪОЛ",
    uz: "CHET ELLIK FUQAROQA UCHUN SIM-KARTANI QANDAY",
    es: "CÓMO OBTENER UNA TARJETA SIM PARA",
    zh: "如何给外国人绑定SIM卡",
    en: "HOW TO GET A SIM CARD AS A",
  },
  "иностранному гражданину": {
    ky: "ТУТАШТЫРЫЛАТ",
    tg: "КАРДАН МУМКИН АСТ",
    uz: "ULASH MUMKIN",
    es: "UN CIUDADANO EXTRANJERO",
    zh: " ",
    en: "FOREIGN CITIZEN",
  },
  "Как подключить": {
    ky: "ЧЕТ ЭЛДИК ЖАРАН ҮЧҮН SIM-КАРТА КАНТИП",
    tg: "SIM-КОРТРО БА ШАҲРВАНДИ ХОРИҶӢ ЧӢ ТАВР ФАЪОЛ",
    uz: "CHET ELLIK FUQAROQA UCHUN SIM-KARTANI QANDAY",
    es: "CÓMO OBTENER UNA TARJETA SIM PARA",
    zh: "如何给外国人绑定SIM卡",
    en: "HOW TO GET A SIM CARD AS A",
    hy: "Ինչպես միացնել ",
    ka: "როგორ დააკავშიროთ ",
    vi: "Cách kích hoạt ",
    hi: "विदेशी नागरिक के",
    kk: "Шетел азаматына ",
    ar: "",
    fr: "Comment connecter ",
    tr: "Yabancı vatandaş ",
    mn: "ГАДААД ИРГЭН",
  },
  "SIM-карту иностранному": {
    ky: "ТУТАШТЫРЫЛАТ",
    tg: "КАРДАН МУМКИН АСТ",
    uz: "ULASH MUMKIN",
    es: "UN CIUDADANO EXTRANJERO",
    zh: " ",
    en: "FOREIGN CITIZEN",
    hy: "SIM քարտը օտարերկրյա ",
    ka: "SIM ბარათი უცხო ქვეყნის ",
    vi: "SIM cho người nước",
    hi: "लिए SIM कार्ड कैसे",
    kk: "SIM-картаны қалай ",
    ar: "",
    fr: "une carte SIM ",
    tr: "için SIM kart nasıl ",
    mn: "SIM КАРТЫГ ХЭРХЭН",
  },
  гражданину: {
    ky: " ",
    tg: " ",
    uz: " ",
    es: " ",
    zh: " ",
    en: " ",
    hy: "քաղաքացուն:",
    ka: "მოქალაქეს",
    vi: "ngoài",
    hi: "कनेक्ट करें",
    kk: "қосу керек",
    ar: "",
    fr: "pour un étranger",
    tr: "bağlanır",
    mn: "ХОЛБОХ ВЭ",
  },
  "Указанная информация действительна с 01.01.2025.": {
    ky: "Көрсөтүлгөн маалымат менен жарактуу 01.01.2025.",
    tg: "Маълумоти зикршуда аз 01.01.2025 эътибор дорад",
    uz: "Ko'rsatilgan ma'lumotlar amal 01qiladi..012025.",
    zh: "指定的信息自2025年01月01日期有效",
    es: "La información indicada es válida a partir del 01.01.2025",
    en: "The information provided is valid as of January 1, 2025.",
    mn: "Заасан мэдээлэл нь хүчин төгөлдөр байна 01.01.2025",
  },
  "1. Получите СНИЛС": {
    ky: "1. СНИЛС алыңыз",
    tg: "1. РСҲИШ гиред",
    uz: "1. SNILS oling",
    zh: "1. 获取个人账户保险号(SNILS)",
    en: "1. OBTAIN A SNILS",
    es: "1. OBTÉN EL SNILS",
    hy: "1. ՍՈՑԻԱԼԱԿԱՆ ՔԱՐՏ ՍՏԱՆԱԼ",
    ka: "1. მიიღეთ სოციალური ბარათი",
    vi: "1. NHẬN SNILS",
    hi: "1. स्निल्स प्राप्त करें",
    kk: "1. ЖЕКЕ ДЕРБЕС ШОТТЫҢ САҚТАНДЫРУ НӨМІРІ (ЖДШСН) АЛЫҢЫЗ",
    ar: "1. احصل على سنیلس (رقم التأمين للحساب الشخصي الفردي)",
    fr: "1. RECEVEZ UN SNILS (numéro d'assurance du compte individuel)",
    tr: "1. SNİLS ALIN",
  },
  "2. Зарегистрируйтесь на Госуслугах": {
    ky: "2. Госуслуги-ге катталыңыз",
    tg: "2. Дар сомонаи хизматрасонии давлатӣ ба қайд монед",
    uz: "2. Gosuslugi-da ro'yxatdan o'ting",
    zh: "2. 在政府服务门户网站(Gosuslugi)上注册",
    en: "2. SIGN UP ON THE GOSUSLUGI PORTAL",
    es: "2. REGÍSTRATE EN EL PORTAL GOSUSLUGI",
    hy: "2. ԳՐԱՆՑՎԵՔ ՊԵՏԱԿԱՆ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԻ ՀԱՄԱՐ",
    ka: "2. დარეგისტრირდით სახელმწიფო სერვისებზე",
    vi: "2. ĐĂNG KÝ DỊCH VỤ CỦA NHÀ NƯỚC",
    hi: "2. गोसुस्लुगी (राज्य सेवाओं के पोर्टल) में रेजिस्टर करें",
    kk: "2. ЕМЛЕКЕТТІК ҚЫЗМЕТТЕРДЕ ТІРКЕЛІҢІЗ",
    ar: "2.  سجل في بوابة الخدمات الحكومية (Gosuslugi)",
    fr: "2. ENREGISTREZ-VOUS AU SITE  - LES SERVICES D’ÉTAT",
    tr: "2. DEVLET HİZMETLERİNDE (QOSUSLUQİ-DE) KAYIT OLUN",
    mn: "2. ГОСУСЛУГАД БҮРТГҮҮЛНЭ ҮҮ",
  },
  "3. Сдайте биометрию": {
    ky: "3. Биометрикаңы тапшырыңыз",
    tg: "3. Биометрия супоред",
    uz: "3. Biometrik ma'lumotlaringizni topshiring",
    zh: "3. 提供生物特征识别数据",
    en: "3. SUBMIT YOUR BIOMETRIC DATA",
    es: "3. PROPORCIONA TUS DATOS BIOMÉTRICOS",
    hy: "3. ՀԱՆՁՆԵՔ ԲԻՈՄԵՏՐԻԿ ՏՎՅԱԼՆԵՐ",
    ka: "3. წარადგინეთ ბიომეტრია",
    vi: "3. NỘP THÔNG TIN SINH TRẮC HỌC",
    hi: "3. बायोमेट्रिक्स सबमिट करें",
    kk: "3. БИОМЕТРИЯ ТАПСЫРЫҢЫЗ",
    ar: "3. قم بتقديم البيانات البيومترية  ",
    fr: "3. REMETTEZ DES DONNÉES BIOMÉRIQUES",
    tr: "3. BİYOMETRİK GÖNDERİN",
    mn: "3. БИОМЕТР ДАТА ХУРААЛГАХ",
  },
  "4. Оформите SIM-карту с выгодным тарифом": {
    ky: "4. Пайдадуу тариф менен SIM картаны алыңыз",
    tg: "4. SIM-кортро бо тарофаи  боманфиат ба расмият дароред",
    uz: "4. Foydali tarifli SIM-kartani rasmiylashtiring",
    zh: "4. 办理具有利套餐的SIM卡",
    en: "4. Get your SIM card with an affordable plan",
    es: "4. Solicita la tarjeta SIM con una tarifa ventajosa",
  },
  "Переведите ваш паспорт в бюро переводов и заверьте у нотариуса.": {
    ky: "Котормо агенттигинде паспортуңузду которуп, нотариус тарабынан күбөлөндүрүңүз",
    tg: "Шиносномаи худро дар бюрои тарҷумонӣ тарҷума намоед ва тасдиқи нотариусро гиред",
    uz: "Pasportingizni tarjima agentligida tarjima qiling va notarius tomonidan tasdiqlang",
    zh: "把您的护照翻译在翻译社并在公证人证明",
    en: "Have your passport translated at a translation agency and notarized",
    es: "Traduce tu pasaporte en una agencia de traducción y certifícalo ante notario",
    hy: "Թարգմանեք ձեր անձնագիրը թարգմանական բյուրոյում և նոտարական հաստատում անցկացրեք:",
    ka: "გადათარგმნეთ თქვენი პასპორტი მთარგმნელობით სააგენტოში და დამოწმებული ნოტარიუსის მიერ.",
    vi: "Hãy nhờ một công ty dịch thuật dịch hộ chiếu của bạn và chứng nhận bởi công chứng viên.",
    hi: "अपने पासपोर्ट का अनुवाद किसी अनुवाद एजेंसी से करवाएँ और नोटरी द्वारा प्रमाणित करवाएँ।",
    kk: "Аударма бюросында төлқұжатыңыздың аудармасын жасатып, нотариуспен куәландырыңыз.",
    ar: "قم بترجمة جواز سفرك في مكتب ترجمة معتمد وقم بتصديقه لدى كاتب العدل.  ",
    fr: "Faites traduire votre passeport dans une agence de traduction et faites la certifier chez un notaire.",
    tr: "Pasaportunuzun tercümesini tercüme bürosunda yaptırın ve noterde onaylatın.",
    mn: "Паспортоо орчуулагч бюронд орчуулж, нотариатаар баталгаажуулна уу",
  },
  "Оформите СНИЛС в Социальном фонде России, МФЦ или у вашего работодателя.": {
    ky: "СНИЛСти Россиянын Социалдык фондунан, МФЦдан же иш берүүчүңүздөн алыңыз.",
    tg: "РСҲИШ-ро дар Фонди иҷтимоии Россия, МБС ё тавассути корфармои худ ба расмият дароред",
    uz: "Rossiya Ijtimoiy fondi, MFTs yoki ish beruvchingizga SNILS uchun ariza bering",
    zh: "在俄罗斯联邦社会保险基金会、多功能政务服务中心(MFC)或您的雇主办理个人账户保险号 (SNILS) ",
    en: "Apply for a SNILS at the Social Fund of Russia, an MFC (Multifunctional Center), or through your employer",
    es: "Solicita el SNILS en el Fondo Social de Rusia, en un MFC (Centro Multifuncional) o a través de tu empleador",
    hy: "Դիմեք Ռուսաստանի Սոցիալական ֆոնդ, Բազմաֆունկցիոնալ կենտրոն (ՄՖԿ) կամ ձեր գործատուի մոտ՝ սոցիալական քարտ ստանալու համար:",
    ka: "მიმართეთ სოციალურ ბარათს რუსეთის სოციალურ ფონდში, მრავალფუნქციურ ცენტრში ან თქვენი დამსაქმებლისგან.",
    vi: "Nộp đơn xin thẻ xã hội tại Quỹ xã hội Nga, một trung tâm đa chức năng hoặc từ người sử dụng lao động của bạn.",
    hi: "रूस के सोशल फंड, एमएफसी (बहुक्रियाशील केंद्र) या अपने नियोक्ता के पास स्निल्स (व्यक्तिगत बीमा खाता संख्या) के लिए एप्लिकेशन जमा करें।",
    kk: "Ресейдің Әлеуметтік қорында, Көпфункционалды орталық (КФО) немесе өзіңіздің жұмыс берушіңізде ЖДШСН ресімдеңіз.",
    ar: "قم بإصدار سنیلس في صندوق الضمان الاجتماعي الروسي، أو في مركزالخدمات المتعددة الوظائف (MFC)، أو من خلال صاحب العمل الخاصبك.",
    fr: "Légalisez le SNILS dans le Fond social de Russie, le Centre multifonctionnel des services publics ou chez votre employeur.",
    tr: "SNILS için Rusya Sosyal Fonu'na, MFTS'ye veya işvereninize başvurun.",
    mn: "ОХУ-ын Нийгмийн сан, МФЦ эсвэл ажил олгогчоор СНИЛС бүртгүүлнэ",
  },
  "Номер СНИЛС выпустят при вас": {
    ky: "Сиздин алдынызда СНИЛС номери берилет",
    tg: "Рақами СҲИШ-ро дар ҳузури шумо чоп мекунанд",
    uz: "SNILS raqami sizning huzuringizda beriladi",
    zh: "个人保险号(SNILS)号码将在您面前下发",
    en: "Your SNILS number will be issued on the spot",
    es: "El número SNILS se emitirá en el momento",
    hy: "Սոցիալական քարտի համարը կտրվի ձեր ներկայությամբ:",
    ka: "სოციალური ბარათის ნომერი გაიცემა თქვენი თანდასწრებით.",
    vi: "Số thẻ xã hội sẽ được cấp khi bạn có mặt.",
    hi: "स्निल्स नंबर फौरन आपकी उपस्थिति में जारी किया जाएगा।",
    kk: "ЖДШС нөмірін сіздің көзіңізше шығарады.",
    ar: "سيتم إصدار رقم سنیلس امامك.",
    fr: "Le numéro de SNILS sera délivré en votre présence.",
    tr: "SNILS numarası sizin huzurunuzda verilecektir.",
    mn: "СНИЛС-ын дугаарыг тантай зэрэг олгоно",
  },
  "Список нотариусов": {
    ky: "Нотариустар тизмеси",
    tg: "Рӯйхати нотариусҳо",
    uz: "Notariuslar ro'yxati",
    hy: "Նոտարների ցուցակ",
    ka: "ნოტარიუსთა სია",
    vi: "",
    hi: "नोटरियों की सूची",
    kk: "Нотариустар тізімі",
    ar: "قائمة كتاب العدل",
    fr: "",
    tr: "Noter listesi",
  },
  "Карта социального фонда": {
    ky: "Социалдык фонддун картасы",
    tg: "Корти фонди иҷтимоӣ",
    uz: "Ijtimoiy fondning xaritasi",
    zh: "俄罗斯联邦社会保险基金会地图",
    en: "Map of Social Fund Branches",
    es: "Mapa de sucursales del Fondo Social",
    hy: "Սոցիալական ֆոնդի քարտ",
    ka: "სოციალური ფონდის ბარათი",
    vi: "",
    hi: "सोशल फंड का मानचित्र",
    kk: "Әлеуметтік қор картасы",
    ar: "خريطة صندوق الضمان الاجتماعي  ",
    fr: "",
    tr: "Sosyal Fon haritası",
  },
  "Карта отделов МФЦ": {
    ky: "МФЦ бөлүмдөрүнүн картасы",
    tg: "Корти шӯъбаҳои МБС",
    uz: "MFTS bo'limlari xaritasi",
    zh: "多功能政务服务中心(MFC)部门地图",
    en: "Map of MFCs",
    es: "Mapa de MFCs",
    hy: "Բազմաֆունկցիոնալ կենտրոնների բաժինների քարտեզ",
    ka: "მრავალფუნქციური ცენტრების განყოფილებების რუკა",
    vi: "Bản đồ các phòng ban của trung tâm đa chức năng",
    hi: "एमएफसी विभागों का मानचित्र",
    kk: "КФО бөлімдерінің картасы",
    ar: "خريطة مكاتب الخدمات المتعددة الوظائف",
    fr: "",
    tr: "",
    mn: "Хэлтсийн карт МФЦ",
  },
  "Возьмите паспорт, оригинал нотариально заверенного перевода и СНИЛС. Также вам потребуется электронная почта или номер телефона российского оператора.":
    {
      ky: "Паспортуңузду, нотариалдык күбөлөндүрүлгөн котормоңуздун түп нускасын жана СНИЛСти алыңыз. Ошондой эле электрондук почта же орус операторунун телефон номери керек болот.",
      tg: "Шиноснома, нусхаи аслии тарҷумаи тасдиқшудаи нотариус ва РСҲИШ-ро гиред. Инчунин ба шумо почтаи электронӣ ё рақами телефони оператори Россия зарур мешавад.",
      uz: "Pasportingizni, notarial tasdiqlangan tarjimaning asl nusxasini va SNILSni oling. Shuningdek, sizga elektron pochta yoki rus operatorining telefon raqami kerak bo'ladi.",
      zh: "带上您的护照、公证翻译原件和个人账户保险号(SNILS).   还需要电子邮件或者俄罗斯运营商电话号码",
      en: "Bring your passport, the notarized translation, and your SNILS. You will also need an email address or a phone number provided by a Russian operator",
      es: "Lleva tu pasaporte, la traducción notariada y tu SNILS. También necesitarás una dirección de correo electrónico o un número de teléfono proporcionado por un operador ruso",
      hy: "Վերցրեք ձեր անձնագիրը, նոտարական վավերացված թարգմանության բնօրինակը և սոց-քարտը: Ձեզ անհրաժեշտ կլինի նաև ռուսաստանյան օպերատորի էլեկտրոնային փոստ կամ հեռախոսահամար:",
      ka: "აიღეთ თქვენი პასპორტი, ორიგინალი ნოტარიულად დამოწმებული თარგმანი და SNILS. ასევე დაგჭირდებათ რუსული ოპერატორის ელ.ფოსტა ან ტელეფონის ნომერი.",
      vi: "Mang theo hộ chiếu, bản dịch công chứng gốc và thẻ xã hội. Bạn cũng sẽ cần email hoặc số điện thoại của nhà điều hành Nga.",
      hi: "अपना पासपोर्ट, मूल नोटरीकृत अनुवाद और स्निल्स ले जाएँ। आपके लिए ईमेल या रूसी ऑपरेटर का फ़ोन नंबर भी ज़रूरी होगा।",
      kk: "Төлқұжатыңызды, нотариалды куәландырылған аударманың түпнұсқасын және ЖДШСН алыңыз. Сондай-ақ, сізге электрондық пошта немесе ресейлік оператордың телефон нөмірі қажет.",
      ar: "أحضر جواز سفرك، النسخة الأصلية للترجمة الموثقة، وسنیلس. ستحتاج أيضًا إلى بريد إلكتروني أو رقم هاتف لمشغل روسي.  ",
      fr: "Prenez votre passeport, sa traduction notariée et le SNILS. Vous aures également besoin d’une adresse e-mail ou un numéro de téléphone d’un opérateur russe.",
      tr: "Pasaportunuzu, noter tasdikli orijinal tercümesini ve SNILS'i yanınıza alın. Ayrıca e-posta adresine veya Rusya operatörünün telefon numarasına da ihtiyacınız olacak.",
      mn: "Паспорт, нотариатаар баталгаажуулсан орчуулгын оригинал болон СНИЛС-ыг аваарай. Мөн Оросын утасны операторын дугаар эсвэл цахим шуудан хэрэгтэй",
    },
  "Обратитесь в банк или МФЦ.": {
    ky: "Банкка кайрылыңыз, мисалы же МФЦ",
    tg: "Ба бонк, масалан ё МБС муроҷиат намоед",
    uz: "Bank bilan bog'laning, masalan yoki MFTS",
    zh: "请联系银行，或多功能政务 服务中心(MFC)",
    en: "Visit a bank or an MFC office",
    es: "Visita un banco o un MFC",
    hy: "Կապվեք բանկի հետ կամ Բազմաֆունկցիոնալ կենտրոնների հետ:",
    ka: "დაუკავშირდით ბანკს ან მრავალფუნქციურ ცენტრებს.",
    vi: "Liên hệ với một ngân hàng như hoặc một trung tâm đa chức năng.",
    hi: "किसी बैंक या एमएफसी (बहुक्रियाशील केंद्र) से संपर्क करें।",
    kk: "Банкке немесе КФО-ға хабарласыңыз.",
    ar: "اتصل بالبنك الخاص بك أو بمركز الخدمة متعدد الوظائف.",
    fr: "Adressez-vous à une banque ou Centre multifonctionnel des services publics.",
    tr: "Bankayla ya da MFC'yle iletişime geçin.",
    mn: "Банк эсвэл МФЦ-д хандана уу",
  },
  "Учетная запись будет подтверждена в день обращения": {
    ky: "Каттоо ошол эле күнү тастыкталат.",
    tg: "Аккаунт дар санаи муроҷиат тасдиқ карда мешавад",
    uz: "Ro'yxatdan o'tish shu kunning o'zida tasdiqlanadi.",
    zh: "账号将在申请当天确认",
    en: "Your account will be verified on the same day",
    es: "La cuenta será verificada el mismo día",
    hy: "Հաշիվը կհաստատվի դիմումի օրը:",
    ka: "ანგარიში დადასტურდება განაცხადის დღეს.",
    vi: "Tài khoản sẽ được xác nhận vào ngày nộp đơn.",
    hi: "एप्लिकेशन जमा करने के दिन ही खाते की पुष्टि कर दी जाएगी।",
    kk: "Есептік жазба өтініш берілген күні расталады.",
    ar: "سيتم تأكيد حسابك في نفس يوم التقديم.",
    fr: "",
    tr: "Başvuru günü hesap onayı yapılacaktır.",
    mn: "Бүртгэлийн данс хандалтын өдөр баталгаажих болно",
  },
  "Карта банков и МФЦ": {
    ky: "Банктар менен МФЦнын картасы",
    tg: "Корти бонкҳо ва МБС",
    uz: "Banklar va MFTS xaritasi",
    zh: "银行和多功能政务服务中心(MFC)地图",
    en: "Map of Banks and MFCs",
    hy: "Բազմաֆունկցիոնալ կենտրոնների բաժինների և բանկերի քարտեզ",
    ka: "",
    vi: "",
    hi: "बैंकों और एमएफसी विभागों का मानचित्र",
    kk: "Банктер мен КФО картасы",
    ar: "خريطة البنوك ومكاتب الخدمات المتعددة الوظائف",
    fr: "",
    tr: "Bankaların ve MFTS departmanlarının haritası",
    mn: "Банк болон МФЦ-ийн газрын зураг",
  },
  "Возьмите паспорт, оригинал нотариально заверенного перевода и СНИЛС. Также вам потребуется электронная почта.":
    {
      ky: "Паспортуңузду, нотариалдык жактан күбөлөндүрүлгөн түпнуска котормоңузду жана СНИЛСти алыңыз. Ошондой эле электрондук почта дареги керек болот.",
      tg: "Шиноснома, нусхаи аслии тарҷумаи тасдиқшудаи нотариус ва РСҲИШ-ро гиред. Инчунин ба шумо почтаи электронӣ ё рақами телефони оператори Россия зарур мешавад.",
      uz: "Pasportingizni, notarial tasdiqlangan tarjimaning asl nusxasini va SNILSni oling. Shuningdek, sizga elektron pochta manzili kerak bo'ladi.",
      zh: "带上您的护照、公证翻译原件和个人账户保险号(SNILS). 还需要电子邮件",
      en: "Bring your passport, the notarized translation, and your SNILS. You will also need an email address",
      es: "Lleva tu pasaporte, la traducción notariada y tu SNILS. También necesitarás una dirección de correo electrónico",
      hy: "Վերցրեք ձեր անձնագիրը, նոտարական հաստատումով թարգմանության բնօրինակը և ՍՈՑԻԱԼԱԿԱՆ ՔԱՐՏ -ը: Նաև ձեզ անհրաժեշտ կլինի էլեկտրոնային փոստ:",
      ka: "აიღეთ თქვენი პასპორტი, ორიგინალი ნოტარიულად დამოწმებული თარგმანი და SNILS. ასევე დაგჭირდებათ ელ.წერილი.",
      vi: "Mang theo hộ chiếu, bản dịch công chứng gốc và SNILS. Bạn cũng sẽ cần một email.",
      hi: "अपना पासपोर्ट, मूल नोटरीकृत अनुवाद और स्निल्स ले जाएँ। आपके लिए ईमेल भी ज़रूरी होगा।",
      kk: "Төлқұжатыңызды, нотариалды куәландырылған аударманың түпнұсқасын және ЖДШСН алыңыз. Сондай-ақ сізге электрондық пошта қажет болады.",
      ar: "أحضر جواز سفرك، النسخة الأصلية للترجمة الموثقة، وسنیلس. ستحتاج أيضًا إلى بريد إلكتروني.  ",
      fr: "Prenez votre passeport, la traduction notariée et le SNILS. Vouz aurez également besoin d’un email.",
      tr: "Pasaportunuzu, noter tasdikli orijinal tercümesini ve SNILS'i yanınıza alın. Ayrıca bir e-postaya da ihtiyacınız olacak.",
      mn: "Паспорт, нотариатаар баталгаажуулсан орчуулгын оригинал болон СНИЛС-ыг аваарай. Мөн цахим шуудангийн хаяг хэрэгтэй",
    },
  "Обратитесь в банк и попросите снять ваши биометрические данные и внести их в Единую биометрическую систему.":
    {
      ky: "Банкка кайрылыңыз жана алардан биометрикалык маалыматыңызды алып, Бирдиктүү биометрикалык системага киргизүүнү сураныңыз.",
      tg: "Ба бонк муроҷиат намоед ва хоҳиш намоед, ки маълумоти биометрии шуморо гиранд ва ба Низоми ягонаи биометрӣ ворид созанд.",
      uz: "Bank bilan bog'laning va ulardan biometrik ma'lumotlaringizni olib, Yagona biometrik tizimga kiritishlarini so'rang.",
      zh: "请联系银行，提交您的生物特征识别数据并记入统一生物识别系统。",
      en: "Visit a bank and request to have your biometric data recorded and entered into the Unified Biometric System.",
      es: "Dirígete a un banco y solicita que registren tus datos biométricos e ingresen la información en el Sistema Biométrico Unificado.",
      hy: "Դիմեք բանկ և խնդրեք վերցնել ձեր բիոմետրիկ տվյալները և դրանք ավելացնել Միասնական բիոմետրիկ համակարգում:",
      ka: "დაუკავშირდით ბანკს და სთხოვეთ აიღოთ თქვენი ბიომეტრიული მონაცემები და შეიყვანოთ ისინი ერთიან ბიომეტრიულ სისტემაში.",
      vi: "Liên hệ với một ngân hàng và yêu cầu họ lấy dữ liệu sinh trắc học của bạn và nhập vào Hệ thống sinh trắc học hợp nhất.",
      hi: "किसी बैंक से संपर्क करें और अपना बायोमेट्रिक डेटा लेकर उसे एकीकृत बायोमेट्रिक प्रणाली में दर्ज करने के लिए कहें।",
      kk: "Банкке хабарласыңыз және биометриялық деректеріңізді алып, оны Бірыңғай биометриялық жүйеге енгізуді сұраңыз.",
      ar: "توجه إلى البنك واطلب أخذ بياناتك البيومترية وإدخالها في النظام البيومتري الموحد.",
      fr: "Adressez-vous à une banque et demandez-les de prendre vos données biométriques et les inscrire au Système biométrique unifié.",
      tr: "Bankayla iletişime geçin ve biyometrik verilerinizi alıp Birleşik Biyometrik Sisteme girmelerini isteyin.",
      mn: "Банкинд хандаж, биометр өгөгдлөө авахуулаад Нэгдсэн биометрийн системд оруулахыг хүс",
    },
  "Биометрия будет подтверждена и зарегистрирована в день обращения": {
    ky: "Биометрия ошол эле күнү тастыкталат жана катталат.",
    tg: "Биометрия дар санаи муроҷиат тасдиқ мегардад ва ба қайд гирифта мешавад",
    uz: "Biometriya shu kunning o'zida tasdiqlanadi va ro'yxatga olinadi.",
    zh: "生物识别信息将在申请当天确认并注册",
    en: "Your biometric data will be verified and registered on the same day",
    es: "Los datos biométricos se verificarán y registrarán el mismo día",
    hy: "Բիոմետրիան կհաստատվի և կգրանցվի դիմելու օրը:",
    ka: "ბიომეტრია დადასტურდება და დარეგისტრირდება განაცხადის დღეს.",
    vi: "Thông tin sinh trắc học sẽ được xác nhận và đăng ký vào ngày nộp đơn.",
    hi: "एप्लिकेशन जमा करने के दिन ही बायोमेट्रिक्स की पुष्टि और रेजिस्टर किया जाएगा।",
    kk: "Биометрия расталады және өтініш берілген күні тіркеледі.",
    ar: "سيتم تأكيد وتسجيل البيانات البيومترية في نفس يوم التقديم.",
    fr: "",
    tr: "Başvuru gününde biyometrik bilgileriniz doğrulanacak ve kayıt altına alınacaktır.",
    mn: "Биометрийн өгөгдөл хандалтын өдөр баталгаажиж бүртгэгдэнэ",
  },
  "Карта банков с биометрией": {
    ky: "Карта банков с биометрией",
    tg: "Карти бонкҳо бо биометрия",
    uz: "Biometrik ma'lumotlarni topshirish uchun banklar xaritasi",
    zh: "带有生物特征识别的银行地址的地图",
    en: "Map of Banks with Biometric Services",
    es: "Mapa de bancos con servicios biométricos",
    hy: "Բիոմետրիկ տվյալներ ընդունող բանկերի քարտեզ",
    ka: "საბანკო ბარათი ბიომეტრიით",
    vi: "Thẻ ngân hàng có sinh trắc học",
    hi: "बायोमेट्रिक्स वाला बैंकों का मानचित्र",
    kk: "Биометрия бар банктер картасы",
    ar: "خريطة البنوك التي توفر خدمات البيومترية",
    fr: "Carte des banques avec biométrie ",
    tr: "Biyometrik özellikli bankalarının haritası",
    mn: "Биометртэй банкны газрын зураг",
  },
  "С паспортом и оригиналом его нотариально заверенного перевода обратитесь в точку продаж t2 с устройством, в котором планируете использовать SIM. Для заключения договора потребуется указать его IMEI (серийный номер устройства)":
    {
      ky: "Паспортуңуз жана нотариалдык жактан күбөлөндүрүлгөн түпнуска котормоңуз менен SIM картаны колдонууну пландаштырган түзмөк менен t2 сатуу пунктуна кайрылыңыз. Келишим түзүү үчүн түзмөгүңүздүн IMEI (түзмөктүн сериялык номери) көрсөтүлүшү керек.",
      tg: "Бо шиноснома ва нусхаи аслии тарҷумаи тасдиқшудаи нотариус ба ҷои фурӯши t2 бо дастгоҳе, ки дар он  SIM истифода хоҳад шуд, муроҷиат намоед. Барои бастани шартнома IMEI-и онро нишон додан зарур аст (рақами силсилавии дастгоҳ)",
      uz: "Pasportingiz va notarial tasdiqlangan asl tarjimasi bilan SIM-kartadan foydalanishni rejalashtirgan qurilma bilan t2 savdo nuqtasi bilan bog'laning. Shartnoma tuzish uchun qurilmangizning IMEI raqami (qurilmaning seriya raqami) ko'rsatilishi kerak.",
      zh: "带着护照和护照公证翻译的原件，请联系t2移动通讯服务零售点并带着您打算使用SIM卡的设备。要签订合同，指定其IMEI (设备序号)",
      en: "Bring your passport and the notarized translation to a t2 store, along with the device where you’ll use the SIM card. To sign the contract, you will need to provide the IMEI (serial number) of your device",
      es: "Lleva tu pasaporte y la traducción notariada a una tienda t2, junto con el dispositivo en el que planeas usar la tarjeta SIM. Para firmar el contrato, deberás proporcionar el IMEI (número de serie) de tu dispositivo",
      hy: "Ձեր անձնագրով և բնօրինակ նոտարական թարգմանությամբ գնացեք t2 վաճառքի կետ այն սարքով, որով նախատեսում եք օգտագործել SIM քարտը: Պայմանագիր կնքելու համար հարկավոր է նշել դրա IMEI-ը (սարքի սերիական համարը):",
      ka: "თქვენი პასპორტით და ორიგინალური ნოტარიულად დამოწმებული თარგმანით, გადადით t2 გაყიდვების პუნქტში იმ მოწყობილობით, რომლითაც აპირებთ SIM-ის გამოყენებას. ხელშეკრულების გასაფორმებლად, თქვენ უნდა მიუთითოთ მისი IMEI (მოწყობილობის სერიული ნომერი).",
      vi: "Mang theo hộ chiếu và bản dịch công chứng gốc, hãy đến điểm bán hàng của T2 cùng với thiết bị mà bạn dự định sử dụng SIM. Để hoàn tất thỏa thuận, bạn sẽ cần phải chỉ ra IMEI (số sê-ri của thiết bị).",
      hi: "अपने पासपोर्ट और मूल नोटरीकृत अनुवाद के साथ और उस डिवाइस के साथ t2 के सेल्स ऑफिस पर जाएँ जिसमें आप SIM का उपयोग करने की योजना बना रहे हैं। अनुबंध करने के लिए आपको डिवाइस का IMEI (डिवाइस सीरियल नंबर) बताना होगा।",
      kk: "Төлқұжатпен және оның нотариалды куәландырылған аудармасының түпнұсқасымен SIM пайдалануды жоспарлап отырған құрылғымен t2 сату нүктесіне хабарласыңыз. Шарт жасау үшін оның IMEI (құрылғының сериялық нөмірі) көрсету талап етіледі.",
      ar: "أحضر جواز سفرك والنسخة الأصلية للترجمة الموثقة إلى نقطة بيع t2 مع الجهاز الذي تنوي استخدام بطاقة SIM عليه.   ستحتاج إلى تقديم الرقم التسلسلي للجهاز(IMEI) عند توقيع العقد.  ",
      fr: "Prenez votre passeport et sa traduction notariée et adressez-vous au point de vente t2 avec l’appareil dans lequel vous allez utiliser la SIM. Pour conclure un contrat vous devrez indiquer son IMEI (numéro de série de l’appareil).",
      tr: "Pasaportunuz ve noter tasdikli tercümesinin orijinalı ile, SIM kartı kullanmayı planladığınız cihazla birlikte t2 satış noktasına gidin. Sözleşme yapabilmek için öncelikle IMEI (cihazın seri numarası) numarasını belirtmeniz gerekmektedir.",
      mn: "Паспорт болон нотариатаар баталгаажуулсан орчуулгаараа t2 худалдааны цэгт SIM карт хэрэглэх төхөөрөмжтэйгээр хандана уу. Гэрээ байгуулахын тулд төхөөрөмжийн IMEI дугаарыг (төхөөрөмжийн серийн дугаар) зааж өгөх шаардлагатай",
    },
  "Сотрудник подготовит договор и сделает проверку сведений о вас по биометрии.":
    {
      ky: "Кызматкер келишим түзүп, биометрика аркылуу маалыматыңызды текшерет.",
      tg: "Корманд шартномаро тайёр мекунад ва маълумоти шуморо тибқи биометрия муқоиса мекунад",
      uz: "Xodim shartnoma tuzadi va siz haqingizdagi ma'lumotlarni tekshiradi.",
      zh: "移动通讯服务网点员将准备合同并检查有关您的生物特征识别数据",
      en: "A store representative will prepare the contract and verify your information using biometric data",
      es: "Un representante preparará el contrato y verificará tus datos mediante biometría",
      hy: "Աշխատակիցը կկազմի պայմանագիր և կստուգի ձեր տվյալները՝ օգտագործելով կենսաչափական տվյալները:",
      ka: "თანამშრომელი მოამზადებს ხელშეკრულებას და გადაამოწმებს თქვენს ინფორმაციას ბიომეტრიის გამოყენებით.",
      vi: "Nhân viên sẽ soạn thảo hợp đồng và xác minh thông tin của bạn bằng phương pháp sinh trắc học.",
      hi: "कर्मचारी एक अनुबंध तैयार करेगा और बायोमेट्रिक्स का उपयोग करके आपकी जानकारी सत्यापित करेगा।",
      kk: "Қызметкер шартты дайындайды және биометрия бойынша сіз туралы мәліметтерді тексереді.",
      ar: "سيقوم الموظف بإعداد العقد والتحقق من بياناتك باستخدام البيانات البيومترية.  ",
      fr: "",
      tr: "Çalışan sözleşmeyi hazırlayacak ve biyometrik verileri kullanarak bilgilerinizi doğrulayacaktır.",
      mn: "Ажилтан гэрээг бэлтгээд, таны биометрийн өгөгдлөөр мэдээллийг шалгана",
    },
  "Для активации SIM через Госуслуги авторизуйтесь под своей учетной записью на устройстве с наличием камеры. Также вы всегда можете обратиться за помощью в точку продаж t2.":
    {
      ky: "SIM-картаны активдештирүү үчүн Госуслуги жеке кабинетке кирип, камерасы бар түзмөк колдонушуңуз керек болот. Ошондой эле сиз ар дайым жардам алуу үчүн t2 сатуу пунктуна кайрыла аласыз.",
      tg: "Барои фаъолкунии SIM тавассути сомонаи хизматрасонии давлатӣ аккаунти худро дар дастгоҳи камерадор мушаххас намоед. Инчунин, шумо ҳамеша метавонед барои кӯмак ба ҷои фурӯши t2 муроҷиат намоед",
      uz: "SIM-kartani faollashtirish uchun Gosusluglarda shaxsiy kabinetga kirishingiz va kamerali qurilmadan foydalanishingiz kerak bo'ladi. Shuningdek, yordam uchun har doim t2 savdo nuqtasiga murojaat qilishingiz mumkin.",
      zh: "要通过政府服务门户网站(Gosuslugi)激活SIM卡，请在带有摄像头 的设备上登录您的帐户. 您也可以随时联系t2移动通讯服务零售点 寻求帮助.",
      en: "To activate the SIM card via Gosuslugi, log into your account on a device with a camera. Assistance is also available at any t2 store",
      es: "Para activar la tarjeta SIM a través de Gosuslugi, inicia sesión en tu cuenta en un dispositivo con cámara. También puedes pedir asistencia en cualquier tienda t2",
      hy: "SIM քարտը պետական ծառայությունների միջոցով ակտիվացնելու համար մուտք գործեք՝ օգտագործելով ձեր հաշիվը տեսախցիկ ունեցող սարքում: Օգնության համար միշտ կարող եք կապվել նաև t2 վաճառքի կետի հետ:",
      ka: "SIM ბარათის გასააქტიურებლად სახელმწიფო სერვისების საშუალებით, შედით თქვენი ანგარიშის გამოყენებით კამერით მოწყობილ მოწყობილობაზე. ასევე ყოველთვის შეგიძლიათ დაუკავშირდეთ t2 გაყიდვების პუნქტს დახმარებისთვის.",
      vi: "Để kích hoạt SIM thông qua Dịch vụ của Nhà nước, hãy đăng nhập bằng tài khoản của bạn trên thiết bị có camera. Bạn cũng có thể liên hệ với điểm bán hàng t2 để được trợ giúp.",
      hi: "गोसुस्लुगी (राज्य सेवाओं के पोर्टल) के ज़रिए SIM सक्रिय करने के लिए, कैमरे वाले डिवाइस पर अपने खाते के लिए लॉग इन करें। आप सहायता के लिए हमेशा t2 के सेल्स ऑफिस से भी संपर्क कर सकते हैं।",
      kk: "SIM-ді Мемлекеттік қызметтер арқылы іске қосу үшін камерасы бар құрылғыдағы есептік жазбаңызға кіріңіз.  Сондай-ақ, сіз әрқашан t2 сату нүктесіне хабарласа аласыз.",
      ar: "لتنشيط بطاقة SIM عبر بوابة الخدمات الحكومية، قم بتسجيل الدخول إلى حسابك على جهاز مزود بكاميرا. يمكنك أيضًا طلب المساعدة من أي نقطة بيع t2.  ",
      fr: "Pour activer la carte SIM à l’aide des Services d’état connectez-vous à l’aide de votre compte à l’appareil doté d’un appareil photo. Vous pouvez aussi vous-adresser au point de vente t2.",
      tr: "SIM kartınızı Devlet Hizmetleri (Qosusluqi) aracılığıyla aktifleştirmek için, kameralı cihazda hesabınızı kullanarak giriş yapın. Ayrıca yardım için her zaman t2 satış noktasına başvurabilirsiniz.",
      mn: "SIM картыг Госуслугаар идэвхжүүлэхийн тулд камертай төхөөрөмжөөсөө өөрийн бүртгэлийн эрхээр нэвтэрнэ үү. Эсвэл t2-ын худалдааны цэгт хандан тусламж авах боломжтой.",
    },
  "После подтверждения личности по биометрии для активации SIM-карты вставьте её в устройство, IMEI которого указан в договоре":
    {
      ky: "Биометрия тарабынан инсандыкты ырастагандан кийин, SIM картаны активдештирүү үчүн, келишимде көрсөтүлгөн аппаратка салыңыз.",
      tg: "Пас аз тасдиқи шахсияти биометрия барои фаъол кардани SIM-карти, онро ба дастгоҳе гузоред, IMEI дар шартнома номбар шудааст.",
      uz: "Biometriya yordamida shaxsingizni tasdiqlaganingizdan so'ng, SIM-kartani faollashtirish uchun uni shartnomada IMEI ko'rsatilgan qurilmaga joylashtiring.",
      zh: "要通过生物识别验证确认身份以后，来激活 SIM 卡，您必须将其插入合同中指定了 IMEI 的设备.",
      en: "After verifying your identity through biometrics, insert the SIM card into the device whose IMEI is specified in the contract.",
      es: "Después de verificar tu identidad mediante biometría, inserta la tarjeta SIM en el dispositivo cuyo IMEI está indicado en el contrato.",
      mn: "Биометрээр танихыг дамжуулсны дараа SIM картыг идэвхжүүлэхийн тулд гэрээнд заагдсан IMEI дугаартай төхөөрөмжинд оруулна уу",
    },
  "Адреса салонов t2": {
    ky: "t2 салондорунун даректери",
    tg: "Суроғаи марказҳои t2",
    uz: "t2 salonlari manzillari",
    zh: "t2移动通讯服务网点地址的地图",
    en: "t2 Store Locations",
    es: "Direcciones de tiendas t2",
    hy: "t2 սրահների հասցեներ",
    ka: "t2 სალონების მისამართები",
    vi: "Địa chỉ của các salon t2",
    hi: "t2 ऑफिसों के पते",
    kk: "t2 салонының мекенжайлары",
    ar: "عناوين مكاتب t2  ",
    fr: "",
    tr: "t2 salonlarının adresleri",
    mn: "t2 салонуудын хаягууд",
  },
  "Подтверждение на Госуслугах": {
    ky: "Госуслуга-да тастыктоо",
    tg: "Тасдиқ дар сомонаи хизматрасонии давлатӣ",
    uz: "Gosuslugi-da tasdiqlash",
    zh: "政府服务门户网站(Gosuslugi)的确认",
    en: "Verification on Gosuslugi",
    es: "Verificación en Gosuslugi",
    hy: "Հաստատեք Պետական ծառայություններում",
    ka: "დადასტურება სახელმწიფო სერვისებზე",
    vi: "Xác nhận về Dịch vụ Nhà nước",
    hi: "गोसुस्लुगी पर पुष्टीकरण",
    kk: "Мемлекеттік қызметтерде растау",
    ar: "التأكيد عبر بوابة الخدمات الحكومية",
    fr: "Vérification au Services d’état",
    tr: "Devlet Hizmetlerinde (Qosusluqi-de) ilişkin onay",
    mn: "Госуслуга дээр баталгаажуулалт",
  },

  "Выбранный регион:": {
    ky: "Тандалган аймак:",
    tg: "Минтақаи интихобшуда:",
    uz: "Tanlangan mintaqa:",
  },
  "Республика Алтай": {
    ky: "Алтай Республикасы",
    tg: "Ҷумҳурии Олтой",
    uz: "Oltoy Respublikasi",
    zh: "阿尔泰共和国",
  },
  "Алтайский край": {
    ky: "Алтай крайы",
    tg: "Кишвари Олтой",
    uz: "Oltoy o'lkasi",
    zh: "阿尔泰边疆区",
  },
  "Архангельская область": {
    ky: "Архангельск облусу",
    tg: "Вилояти Архангелск",
    uz: "Arxangelsk viloyati",
    zh: "阿尔汉格尔斯克州",
  },
  "Республика Бурятия": {
    ky: "Бурятия Республикасы",
    tg: "Ҷумҳурии Бурятия",
    uz: "Buryatiya Respublikasi",
    zh: "布里亚特共和国",
  },
  "Белгородская область": {
    ky: "Белгород облусу",
    tg: "Вилояти Белгород",
    uz: "Belgorod viloyati",
    zh: "别尔哥罗德州",
  },
  "Брянская область": {
    ky: "Брянск облусу",
    tg: "Вилояти Брянск",
    uz: "Bryansk viloyati",
    zh: "布良斯克州",
  },
  "Вологодская область": {
    ky: "Вологда облусу",
    tg: "Вилояти Вологда",
    uz: "Vologda viloyati",
    zh: "沃洛格达州",
  },
  "Владимирская область": {
    ky: "Бишкек облусу",
    tg: "Вилояти Владимир",
    uz: "Vladimir viloyati",
    zh: "弗拉基米尔州",
  },
  "Волгоградская область": {
    ky: "Волгоград облусу",
    tg: "Вилояти Волгоград",
    uz: "Volgograd viloyati",
    zh: "伏尔加格勒州",
  },
  "Воронежская область": {
    ky: "Воронеж облусу",
    tg: "Вилояти Воронеж",
    uz: "Voronej viloyati",
    zh: "沃罗涅日州",
  },
  "Еврейская АО": {
    ky: "Жүйүт АО",
    tg: "Вилояти мухтори Яҳудӣ",
    uz: "Yahudiy AJ",
    zh: "犹太自治州",
  },
  "Ивановская область": {
    ky: "Иваново облусу",
    tg: "Вилояти Иваново",
    uz: "Ivanovo viloyati",
    zh: "伊万诺沃州",
  },
  "Иркутская область": {
    ky: "Иркутск облусу",
    tg: "Вилояти Иркутск",
    uz: "Irkutsk viloyati",
    zh: "伊尔库茨克州",
  },
  "Республика Коми": {
    ky: "Коми Республикасы",
    tg: "Ҷумҳурии Коми",
    uz: "Komi Respublikasi",
    zh: "科米共和国",
  },
  "Республика Карелия": {
    ky: "Карелия Республикасы",
    tg: "Ҷумҳурии Карелия",
    uz: "Kareliya Respublikasi",
    zh: "卡累利阿共和国",
  },
  "Красноярский край (Норильск)": {
    ky: "Красноярск крайы (Норильск)",
    tg: "Кишвари Красноярск (Норилск)",
    uz: "Krasnoyarsk o'lkasi (Norilsk)",
    zh: "克拉斯诺雅尔斯克边疆区（诺里尔斯克",
  },
  "Калининградская область": {
    ky: "Калининград облусу",
    tg: "Вилояти Калининград",
    uz: "Kaliningrad viloyati",
    zh: "加里宁格勒州",
  },
  "Калужская область": {
    ky: "Калуга облусу",
    tg: "Вилояти Калуга",
    uz: "Kaluga viloyati",
    zh: "卡卢加州",
  },
  "Камчатский край": {
    ky: "Камчатка крайы",
    tg: "Кишвари Камчатка",
    uz: "Kamchatka o'lkasi",
    zh: "堪察加边疆区",
  },
  "Кемеровская область": {
    ky: "Кемерово облусу",
    tg: "Вилояти Кемерово",
    uz: "Kemerovo viloyati",
    zh: "克麦罗沃州",
  },
  "Краснодарский край и Республика Адыгея": {
    ky: "Краснодар крайы жана Адыгея Республикасы",
    tg: "Кишвари Краснодар ва Ҷумҳурии Адигея",
    uz: "Krasnodar o'lkasi va Adygeya Respublikasi",
    zh: "克拉斯诺达尔边疆区和阿迪格共和国",
  },
  "Красноярский край (кроме Норильска)": {
    ky: "Красноярск крайы (Норильскиден тышкары)",
    tg: "Кишвари Красноярск (ғайр аз Норилск)",
    uz: "Krasnoyarsk o'lkasi (Norilskdan tashqari)",
    zh: "克拉斯诺雅尔斯克边疆区（除了诺里尔斯克）",
  },
  "Кировская область": {
    ky: "Киров облусу",
    tg: "Вилояти Киров",
    uz: "Kirov viloyati",
    zh: "基洛夫州",
  },
  "Костромская область": {
    ky: "Кострома облусу",
    tg: "Вилояти Кострома",
    uz: "Kostroma viloyati",
    zh: "科斯特罗马州",
  },
  "Курганская область": {
    ky: "Курган облусу",
    tg: "Вилояти Курган",
    uz: "Kurgan viloyati",
    zh: "库尔干州",
  },
  "Курская область": {
    ky: "Курск облусу",
    tg: "Вилояти Курск",
    uz: "Kursk viloyati",
    zh: "库尔斯克州",
  },
  "Санкт-Петербург и Ленинградская область": {
    ky: "Санкт-Петербург жана Ленинград облусу",
    tg: "Санкт-Петербург ва Вилояти Ленинград",
    uz: "Sankt-Peterburg va Leningrad viloyati",
    zh: "圣彼得堡和列宁格勒州",
  },
  "Липецкая область": {
    ky: "Липецк облусу",
    tg: "Вилояти Липетск",
    uz: "Lipetsk viloyati",
    zh: "利佩茨克州",
  },
  "Республика Мордовия": {
    ky: "Мордовия Республикасы",
    tg: "Ҷумҳурии Мордовия",
    uz: "Mordoviya Respublikasi",
    zh: "莫尔多瓦共和国",
  },
  "Республика Марий Эл": {
    ky: "Марий Эл Республикасы",
    tg: "Ҷумҳурии Марий Эл",
    uz: "Mari El Respublikasi",
    zh: "马里埃尔共和国",
  },
  "Магаданская область": {
    ky: "Магадан облусу",
    tg: "Вилояти Магадан",
    uz: "Magadan viloyati",
    zh: "马加丹州",
  },
  "Москва и область": {
    ky: "Москва жана Москва облусу",
    tg: "Москва ва вилоят",
    uz: "Moskva va Moskva viloyati",
    zh: "莫斯科和莫斯科州",
  },
  "Мурманская область": {
    ky: "Мурманск облусу",
    tg: "Вилояти Мурманск",
    uz: "Murmansk viloyati",
    zh: "摩尔曼斯克州",
  },
  "Новосибирская область": {
    ky: "Новосибирск облусу",
    tg: "Вилояти Новосибирск",
    uz: "Novosibirsk viloyati",
    zh: "新西伯利亚州",
  },
  "Нижегородская область": {
    ky: "Нижегородская облусу",
    tg: "Вилояти Нижний Новгород",
    uz: "Nijniy Novgorod viloyati",
    zh: "下诺夫哥罗德州",
  },
  "Новгородская область": {
    ky: "Новгород облусу",
    tg: "Вилояти Новгород",
    uz: "Novgorod viloyati",
    zh: "诺夫哥罗德州",
  },
  "Оренбургская область": {
    ky: "Оренбург облусу",
    tg: "Вилояти Оренбург",
    uz: "Orenburg viloyati",
    zh: "奥伦堡州",
  },
  "Орловская область": {
    ky: "Орел облусу",
    tg: "Вилояти Орлов",
    uz: "Orel viloyati",
    zh: "奥尔洛夫州",
  },
  "Омская область": {
    ky: "Омск облусу",
    tg: "Вилояти Омск",
    uz: "Omsk viloyati",
    zh: "鄂木斯克州",
  },
  "Пензенская область": {
    ky: "Пенза облусу",
    tg: "Вилояти Пенза",
    uz: "Penza viloyati",
    zh: "奔萨州",
  },
  "Пермский край": {
    ky: "Пермь крайы",
    tg: "Кишвари Перм",
    uz: "Perm viloyati",
    zh: "彼尔姆边疆区",
  },
  "Псковская область": {
    ky: "Псков облусу",
    tg: "Вилояти Псков",
    uz: "Pskov viloyati",
    zh: "普斯科夫州",
  },
  "Приморский край": {
    ky: "Приморский крайы",
    tg: "Кишвари Приморе",
    uz: "Primorskiy o'lkasi",
    zh: "滨海边疆区",
  },
  "Ростовская область": {
    ky: "Ростов облусу",
    tg: "Вилояти Ростов",
    uz: "Rostov viloyati",
    zh: "罗斯托夫州",
  },
  "Рязанская область": {
    ky: "Рязань облусу",
    tg: "Вилояти Рязан",
    uz: "Ryazan viloyati",
    zh: "梁赞州",
  },
  "Самарская область": {
    ky: "Самара облусу",
    tg: "Вилояти Самара",
    uz: "Samara viloyati",
    zh: "萨马拉州",
  },
  "Свердловская область": {
    ky: "Свердловск облусу",
    tg: "Вилояти Свердловск",
    uz: "Sverdlovsk viloyati",
    zh: "斯维尔德洛夫斯克州",
  },
  "Саратовская область": {
    ky: "Саратов облусу",
    tg: "Вилояти Саратов",
    uz: "Saratov viloyati",
    zh: "萨拉托夫州",
  },
  "Сахалинская область": {
    ky: "Сахалин облусу",
    tg: "Вилояти Сахалин",
    uz: "Saxalin viloyati",
    zh: "萨哈林州",
  },
  "Смоленская область": {
    ky: "Смоленск облусу",
    tg: "Вилояти Смоленск",
    uz: "Smolensk viloyati",
    zh: "斯摩棱斯克州",
  },
  "Ставропольский край": {
    ky: "Ставрополь крайы",
    tg: "Кишвари Ставрополь",
    uz: "Stavropol viloyati",
    zh: "斯塔夫罗普尔边疆区",
  },
  "Республика Татарстан": {
    ky: "Татарстан Республикасы",
    tg: "Ҷумҳурии Тотористон",
    uz: "Tatariston Respublikasi",
    zh: "鞑靼斯坦共和国",
  },
  "Тамбовская область": {
    ky: "Тамбов облусу",
    tg: "Вилояти Тамбов",
    uz: "Tambov viloyati",
    zh: "坦波夫州",
  },
  "Тверская область": {
    ky: "Тверь облусу",
    tg: "Вилояти Твер",
    uz: "Tver viloyati",
    zh: "特维尔州",
  },
  "Томская область": {
    ky: "Томск облусу",
    tg: "Вилояти Томск",
    uz: "Tomsk viloyati",
    zh: "托木斯克州",
  },
  "Тульская область": {
    ky: "Тула облусу",
    tg: "Вилояти Тула",
    uz: "Tula viloyati",
    zh: "图拉州",
  },
  "Тюменская область": {
    ky: "Талас облусу",
    tg: "Вилояти Тюмен",
    uz: "Tyumen viloyati",
    zh: "秋明州",
  },
  "Ульяновская область": {
    ky: "Ульяновск облусу",
    tg: "Вилояти Ульяновск",
    uz: "Ulyanovsk viloyati",
    zh: "乌里扬诺夫斯克州",
  },
  "Удмуртская Республика": {
    ky: "Удмурт Республикасы",
    tg: "Ҷумҳурии Удмуртия",
    uz: "Udmurtiya Respublikasi",
    zh: "乌德穆尔特共和国",
  },
  "Республика Хакасия и Республика Тыва": {
    ky: "Хакасия Республикасы жана Тыва Республикасы",
    tg: "Ҷумҳурии Хакасия ва Ҷумҳурии Тува",
    uz: "Xakasiya Respublikasi va Tuva Respublikasi",
    zh: "哈卡斯共和国和图瓦共和国",
  },
  "Хабаровский край": {
    ky: "Хабаровск крайы",
    tg: "Кишвари Хабаровск",
    uz: "Xabarovsk o'lkasi",
    zh: "哈巴罗夫斯克边疆区",
  },
  "Ханты-Мансийский АО—Югра": {
    ky: "Ханты-Мансийский АО-Югра",
    tg: "Ҳавзаи Мухтори Ханти-Мансийск -Югра",
    uz: "Xanti-Mansi avtonom okrugi",
    zh: "汉特-曼西斯克自治区 - 尤格拉 ",
  },
  "Чувашская Республика": {
    ky: "Чуваш Республикасы",
    tg: "Ҷумҳурии Чувашистон",
    uz: "Chuvacha Respublikasi",
    zh: "楚瓦什共和国",
  },
  "Челябинская область": {
    ky: "Челябинск облусу",
    tg: "Вилояти Челябинск",
    uz: "Chelyabinsk viloyati",
    zh: "车里雅宾斯克州",
  },
  "Ямало-Ненецкий АО": {
    ky: "Ямало-Ненец АО",
    tg: "Ҳавзаи Мухтори Ямалу Ненетс",
    uz: "Yamalo-Nenets avtonom okrugi",
    zh: "亚马尔-涅涅茨自治区",
  },
  "Ярославская область": {
    ky: "Ярославль облусу",
    tg: "Вилояти Ярославль",
    uz: "Yaroslav viloyati",
    zh: "雅罗斯拉夫尔州",
  },
  "На мобильные номера России": {
    es: "A números móviles en Rusia y llamadas ilimitadas a números t2 Rusia",
    zh: "到俄罗斯手机号码和无限到俄罗斯手机t2号码",
    ky: "Россиядагы мобилдик номерлерге жана Россияда t2 номерине чексиз",
    uz: "Rossiya mobil raqamlariga va Rossiya t2 raqamlariga cheksiz qo‘ng‘iroqlar",
    en: "To mobile numbers in Russia and unlimited calls to t2 Russia",
  },
  "на номера t2 России": {
    es: " ",
    zh: " ",
    ky: " ",
    uz: " ",
    en: " ",
  },
  "ВЕЧНЫЕ МИНУТЫ И ГБ": {
    es: "MINUTOS Y GB ACUMULABLES",
    zh: "无尽的分钟和GB",
    en: "CARRY OVER MINUTES AND DATA",
  },
  "На любой скорости – при первом подключении": {
    es: "A cualquier velocidad, disponible en la primera conexión",
    zh: "任何速度 – 首次连接时",
    en: "At any speed, available with the first activation",
  },
  "Мобильный интернет": {
    zh: "手机网络",
    mn: "Гар утасны интернет",
  },
  "ХВАТИТ!": {
    ky: "ЖЕТИШТҮҮ!",
    en: "ENOUGH!",
    fr: "Assez!",
    ka: "",
    kk: "",
    tr: "Yeterli!",
    vi: "Đủ rồi!",
    hi: "काफ़ी!",
    hy: "",
  },
  "Неограниченный интернет‑трафик на любые сервисы": {
    es: "",
    zh: "",
    ky: "",
    uz: "Istalgan xizmatlar uchun cheksiz internet-trafik",
    en: "",
  },
  "МЕСЯЦ БЕСПЛАТНОГО ДОМАШНЕГО ИНТЕРНЕТА": {
    es: "UN MES DE INTERNET EN EL HOGAR GRATIS",
    zh: "",
    ky: "",
    uz: "",
    en: "ONE MONTH OF FREE HOME BROADBAND",
  },
  Верно: {
    fr: "C'est vrai",
  },
};

export { dictionary, fixT, t };

import { regions } from "@/constants";
import useLanguage from "@/hooks/useLanguage";
import useQuery from "@/hooks/useQuery";
import store from "@/store";
import { computed } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/detail/:slug",
    name: "Detail",
    component: () => import("@/views/DetailView.vue"),
  },
  {
    path: "/frame-detail/:slug",
    name: "FrameDetail",
    component: () => import("@/views/FrameDetailView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

const t2TariffsSlug = [
  "enough",
  "everywhere-online",
  "black",
  "super-online-plus",
  "my-online-plus",
  "my-online",
  "my-conversation",
  "gaming",
  "premium",
];
const apiTariffsSlug = ["prozrachnie-granicy-l", "prozrachnie-granicy-m"];

const onceLoad = onceLoadHandler();

router.beforeEach(async (to) => {
  await onceLoad();

  if (t2TariffsSlug.includes(to.params.slug) && to.name !== "FrameDetail") {
    return { path: `/frame-detail/${to.params.slug}`, query: to.query };
  }
  if (apiTariffsSlug.includes(to.params.slug) && to.name !== "Detail") {
    return { path: `/detail/${to.params.slug}`, query: to.query };
  } else return true;
});

function onceLoadHandler() {
  let isLoad = false;

  return () => {
    if (isLoad) return;

    return new Promise((resolve) => {
      const { getQueryByName } = useQuery();
      const { setLanguage } = useLanguage();

      const langQp = getQueryByName("lang");
      if (langQp) {
        setLanguage(langQp);
      }

      const regionQp = getQueryByName("region");
      if (regionQp in regions) {
        const newRegion = { name: regions[regionQp].name, siteId: regionQp };
        store.commit("setProp", {
          regionConfirm: newRegion,
        });
      }
      const regionConfirm = computed(() => store.state.api.regionConfirm);

      const fetchData = async () => {
        store.commit("setProp", { isGlobalLoading: true });
        if (!regionConfirm.value) {
          // j();
          await store.dispatch("fetchRegion");
        }
        await store.dispatch("fetchTariffs", {
          siteId: regionConfirm.value?.siteId,
        });
        await store.dispatch("fetchApiTariffs", {
          region: regionConfirm.value?.siteId,
        });
        store.commit("setProp", { isGlobalLoading: false });
        resolve();
      };

      isLoad = true;
      fetchData();
    });
  };
}

export default router;
